import instance from "./http";
import qs from 'qs'
// 管理所有接口
  const $api = {
    /**公用开始**/
    //获取ip
    getLocationCity(params){
      return instance.get(`/pricing-user/v2.0/location/city`,{params})
    },
    //上传文件
    postFileUpload(params){
      return instance.post('/pricing-user/file/upload',params,{headers: {'application': 'multipart/form-data'}})
    },
    //删除文件
    postFileDelete(params){
      return instance.post(`/pricing-user/file/delete?${qs.stringify(params)}`,params,{headers: {'application': 'application/json'}})
    },
    //根据客户信息发送短信
    postSendCustomerSms(customerId,params){
      return instance.post(`/pricing-check/sms/sendCustomerSms/${customerId}`,params,{headers: {'application': 'application/json'}})
    },
    //校验短信码
    postValidSmsCode(params){
      return instance.post(`/pricing-user/sms/validSmsCode?${qs.stringify(params)}`,{headers: {'application': 'application/json'}})
    },
    //刷新并返回新的登录令牌[根据登录时返回的tokenExpireTime和当前时间比较，在过期前30秒-5分钟之间调用此接口并返回新的token,把原先的token替换掉]
    postRefreshToken(params){
      return instance.post(`/pricing-user/user/refreshToken`,params,{headers: {'application': 'application/json'}})
    },
    /**公用结束**/
    /**工作台开始**/
    //办证工作台
    getWorkbenchCertCount(params){
      return instance.get(`/pricing-trade/workbench/cert/count`,{params})
    },
    //销售助理工作台(部分数据)
    getDealBookMerchantCount(params){
      return instance.get(`/pricing-check/dealBook/merchant/count`,{params})
    },
    //销售助理工作台(部分数据)
    getWorkbenchSalesCount(params){
      return instance.get(`/pricing-trade/workbench/sales/count`,{params})
    },
    //销售助理/帮卖经理工作台【包含: 验车，合同，收发货，差异评定，库存车，车辆钥匙，价格确认等】
    getWorkbenchSellerCount(shopId,params){
      return instance.get(`/pricing-trade/workbench/seller/count/${shopId}`,{params})
    },
    //帮卖经理-工作台（检测待预约）
    getCheckBookFollowUpCount(shopId,params){
      return instance.get(`/pricing-check/customer/checkBook/followUp/count/${shopId}`,{params})
    },
    //帮卖经理-工作台（检测待分配/次日检测跟进）
    getCheckCount(shopId,params){
      return instance.get(`/pricing-check/check/count/${shopId}`,{params})
    },
    //帮卖经理-工作台（竞拍延期跟进）
    getExtensionAuctionNoFollowUpCount(shopId,params){
      return instance.get(`/pricing-check/checkReport/extensionAuction/noFollowUp/count/${shopId}`,{params})
    },
    //帮卖经理-工作台（成交待预约/新任务/当日预约跟进）
    getDealBookCount(shopId,params){
      return instance.get(`/pricing-check/dealBook/count/${shopId}`,{params})
    },
    // 检测坐席-工作台
    getCheckSeatCount(params){
      return instance.get(`/pricing-check/customer/check/seat/count`,{params})
    },

    //库管员-待确认价格
    getAuctionPriceCount(params){
      return instance.get(`/pricing-auction/car/auction/price/confirm/count`,{params})
    },
    //门店店长工作台-垫资复检差异待评定数量
    postDiffEvaluateCount(shopId,params){
      return instance.post(`/pricing-trade/valid/diff/evaluate/count/${shopId}`,params,{headers: {'application': 'application/json'}})
    },
    

    
    /**工作台结束**/
    //登入
    postLogin(params){
      return instance.post('/pricing-user/user/login',params,{headers: {'application': 'application/json'}})
    },
    //当前登录账号是否已经有人登录,true是，false否
    postYetLogin(params){
      return instance.post('/pricing-user/user/yet/login',params,{headers: {'application': 'application/json'}})
    },
     //退出系统
    postLogout(params){
      return instance.post('/pricing-user/user/logout',params)
    },
    //根据roleId获取部门
    getListDepartmentByRoleId(roleId,params){
      return instance.get(`/pricing-user/department/listDeptByUserIdAndRoleId/${roleId}`,{params})
    },
    //新增用户
    postUserAdd(params){
      return instance.post(`/pricing-user/user/add`,params,{headers: {'application': 'application/json'}})
    },
    //根据roleId获取登录用户的门店
    getListShopByRoleId(roleId,params){
      return instance.get(`/pricing-user/shop/listShopByRoleId/${roleId}`,{params})
    },

    
    

    //根据用户id查询其拥有的所有角色
    getRoleByUserId(params){
      return instance.get(`/pricing-user/role/getRoleByUserId/${params}`)
    },
    //根据角色ID获取用户列表
    getUserByRoleId(params){
      return instance.get(`/pricing-user/user/getUserByRoleId/${params}`)
    },
    //根据用户ID获取用户及角色菜单资源权限
    getUserData(params){
      return instance.get(`/pricing-user/user/get/${params}`)
    },
    //根据用户ID获取用户简单信息
    getUserSimple(id){
      return instance.get(`/pricing-user/user/simple/get/${id}`)
    },
    //根据用户id切换其当前角色
    changeCurrentRoleByUserId(userid,params){
      return instance.post(`/pricing-user/role/changeCurrentRoleByUserId/${userid}`,params)
    },
    //获取当前登录账户信息
    getUserInfoByToken(params){
      return instance.post('/pricing-user/user/getUserInfoByToken',params)
    },
    //重置登录密码
    resetPwd(params){
      return instance.post('/pricing-user/user/resetPwd',params)
    },
    //发送短信验证码
    postSendSmsCode(params){
      return instance.post(`/pricing-user/sms/sendSmsCode`,params)
    },
    //根据userId获取部门
    getDepartmentByUserId(params){
      return instance.get(`/pricing-user/department/getDepartmentByUserId/${params}`)
    },
    //根据用户id查询当前部门
    getCurrentDepartmentByUserId(params){
      return instance.get(`/pricing-user/department/getCurrentDepartmentByUserId/${params}`)
    },
    //根据用户id切换其当前部门
    changeCurrentDepartmentByUserId(userId,currentRoleId,params){
      return instance.post(`/pricing-user/department/changeCurrentDepartmentByUserId/${userId}/${currentRoleId}`,params)
    },
    //根据departIds递归部门以及其下的用户
    recursionQueryUserListByDepartIds(params){
      return instance.post(`/pricing-user/department/recursionQueryUserListByDepartIds`,params)
    },
    //根据userId获取所在门店列表
    getShopsByUserId(params){
      return instance.get(`/pricing-user/department/getShopsByUserId`,{params})
    },
    

    //搜索用户,此接口不做模糊查询【不分页】
    postQueryUserList(params){
      return instance.post(`/pricing-user/user/queryUserList`,params,{headers: {'application': 'application/json'}})
    },
    //根据角色和部门查询用户
    queryUserListByRoleAndDepart(params){
      return instance.post(`/pricing-user/user/queryUserListByRoleAndDepart`,params,{headers: {'application': 'application/json'}})
    },
    //查询简单的用户列表[不分页]
    postSimpleQueryUserList(params){
      return instance.post(`/pricing-user/user/simple/queryUserList`,params,{headers: {'application': 'application/json'}})
    },
    //根据角色/部门/用户查询用户
    queryUserListByRoleAndRoleLevelAndDepartAndUser(params){
      return instance.post(`/pricing-user/user/queryUserListByRoleAndRoleLevelAndDepartAndUser`,params,{headers: {'application': 'application/json'}})
    },
    //根据角色ID递归查询该角色下的所有菜单
    getRecursionMenuByRoleId(roleId,params){
      return instance.get(`/pricing-user/menu/getRecursionMenuByRoleId/${roleId}`,params)
    },
    //根据用户id查询当前角色 如果isCurrent，0：是，1：不是
    getCurrentRoleByUserId(userId,params){
      return instance.get(`/pricing-user/role/getCurrentRoleByUserId/${userId}`,params)
    },
    /**供应商开始**/
    //搜索供应商
    postSupplierQuery(params){
      return instance.post('/pricing-user/supplier/query',params,{headers: {'application': 'application/json'}})
    },
    //查询供应商列表【不分页】
    postSupplierList(params){
      return instance.post('/pricing-user/supplier/list',params,{headers: {'application': 'application/json'}})
    },
    

    /**供应商结束**/
    //根据code递归获取行政地区,含级联数据
    getRecursionRegionByCode(params){
      return instance.post(`/pricing-user/region/getRecursionRegionByCode?${qs.stringify(params)}`,params,{headers: {'application': 'application/json'}})
    },

    
    
    /** 互动-begin **/
    //车牌所在地缩写查询
    getCarNoLocationShort(params){
      return instance.get('/pricing-check/baseCar/carNoLocation/short/query',{params})
    },
    // 车辆品牌 列表查询
    baseCarBrandList(params){
      return instance.get('/pricing-check/baseCar/maker/series/list',{params})
    },
    // 车系 列表查询
    baseCarSeriesList(params){
      return instance.post('/pricing-check/baseCar/series/list',params,{headers: {'application': 'application/json'}})
    },
    // 客户-保存客户基本数据
    customerSaveCustomer(params){
      return instance.post('/pricing-check/customer/saveCustomer',params,{headers: {'application': 'application/json'}})
    },
    // 客户-新建客户
    customerNewCustomer(params){
      return instance.post('/pricing-check/customer/newCustomer',params,{headers: {'application': 'application/json'}})
    },
    //返回解密后的客户电话
    getRspDecryptCustomerPhone(params){
      return instance.get('/pricing-check/call/rspDecryptCustomerPhone',{params})
    },
    // 模糊匹配车辆品牌车系
    carFamilyQueryCarFamily(params){
      return instance.get(`/pricing-check/carFamily/queryCarFamily`,{params})
    },
    // 客户-客户渠道查询
    customerQueryChannel(params){
      return instance.get(`/pricing-check/customer/queryChannel`,{params})
    },
    //新增客户渠道
    postCustomerChannelAdd(params){
      return instance.post('/pricing-check/customer/channel/add',params,{headers: {'application': 'application/json'}})
    },
    //修改客户渠道
    postCustomerChannelUpdate(ccId,params){
      return instance.post(`/pricing-check/customer/channel/update/${ccId}`,params,{headers: {'application': 'application/json'}})
    },
    //客户-客户手机号排重
    customerExistCustomerPhone(params){
      return instance.get(`/pricing-check/customer/existCustomerPhone/${params}`)
    },
    //客户-客户详情查询(根据业务跟进ID查询客户详情)
    customerQueryCustomerByFollowUpId(params){
      return instance.get(`/pricing-check/customer/queryCustomerByFollowUpId/${params}`)
    },
    //修改次要电话
    postUpdateOtherPhone(customerId,params){
      return instance.post(`/pricing-check/customer/updateOtherPhone/${customerId}`,params,{headers: {'application': 'application/json'}})
    },
    //根据客户手机号查询有效客户
    getByPhoneList(phone,params){
      return instance.get(`/pricing-check/customer/byPhone/list/${phone}`,{params})
    },
    //根据车牌号查询有效客户
    getCustomerByCarNoList(carNo,params){
      return instance.get(`/pricing-check/customer/byCarNo/list/${carNo}`,{params})
    },
    
    


    //客户线索入口查询
    customerQueryCustomerClue(params){
      return instance.get(`/pricing-check/customer/queryCustomerClue/${params}`)
    },
    //客户-加提醒
    messageSaveUserMessage(params){
      return instance.post(`/pricing-check/message/saveUserMessage`,params,{headers: {'application': 'application/json'}})
    },
    //客户-更新客户信息
    customerEditCustomer(params){
      return instance.post(`/pricing-check/customer/editCustomer`,params,{headers: {'application': 'application/json'}})
    },
    //服务记录标题-大小标题
    customerGetServiceTitle(params){
      return instance.get(`/pricing-check/customer/getServiceTitle`,{params})
    },
    //客户-生成临时牌照
    customerGenerateTempCarNo(){
      return instance.get(`/pricing-check/customer/generateTempCarNo`)
    },
    //客户-客户列表查询
    customerQueryCustomer(params){
      return instance.post(`/pricing-check/customer/queryCustomer`,params,{headers: {'application': 'application/json'}})
    },
    //互动工具-新消息数量查询
    messageQueryMessageCount(params){
      return instance.get(`/pricing-check/message/queryMessageCount?${qs.stringify(params, { indices: false })}`)
    },
    //互动工具-消息查询
    messageQueryMessage(params){
      return instance.get(`/pricing-check/message/queryMessage`,{params})
    },
    //互动工具-消息已读
    messageReadMessage(params){
      return instance.get(`/pricing-check/message/readMessage`,{params})
    },
    //用户分页查询首页 预警提示 消息
    postMessageWarningPage(params){
      return instance.post(`/pricing-check/message/warning/page`,params,{headers: {'application': 'application/json'}})
    },
    //根据消息 id 删除
    DeleteMessage(params){
      return instance.get(`/pricing-check/message/delete`,{params})
    },
    //工具-短信-短信模版标题查询
    smsQueryTitle(params){
      return instance.get(`/pricing-check/sms/queryTitle${[0,1].includes(params)?'/'+params:''}`,)
    },
    //工具-短信-短信模版查询
    smsQuerySmsTemplate(params){
      return instance.get(`/pricing-check/sms/querySmsTemplate?${qs.stringify(params, { indices: false })}`)
    },
    //工具-短信-根据手机号码发送短信 支持批量发送
    smsSendSms(params){
      return instance.post(`/pricing-check/sms/sendSms`,params,{headers: {'application': 'application/json'}})
    },
    //根据客户信息发送短信
    smsSendCustomerSms(customerId,params){
      return instance.post(`/pricing-check/sms/sendCustomerSms/${customerId}`,params,{headers: {'application': 'application/json'}})
    },
    //客户-服务类型大小类
    customerGetServiceType(){
      return instance.get(`/pricing-check/customer/getServiceType`)
    },
    //客户-客户列表查询
    customerQueryClueCustomer(params){
      return instance.post(`/pricing-check/customer/queryClueCustomer`,params,{headers: {'application': 'application/json'}})
    },
    //客户-检测坐席新任务
    customerQueryNoFollowUp(params){
      return instance.post(`/pricing-check/customer/queryNoFollowUp`,params,{headers: {'application': 'application/json'}})
    },
    //客户-检测坐席跟进任务
    // customerQueryFollowingUp(params){
    //   return instance.post(`/pricing-check/customer/queryFollowingUp`,params,{headers: {'application': 'application/json'}})
    // },
    //检测预约成功任务
    checkBookQueryBookSuccess(params){
      return instance.post(`/pricing-check/checkBook/queryBookSuccess`,params,{headers: {'application': 'application/json'}})
    },
    //检测预约失败任务（获取放弃客户）
    checkBookQueryBookFail(params){
      return instance.post(`/pricing-check/customer/queryAbandon`,params,{headers: {'application': 'application/json'}})
    },
    //放弃
    postGiveUpCustomer(params){
      return instance.post(`/pricing-check/customer/giveUpCustomer?${qs.stringify(params)}`,params,{headers: {'application': 'application/json'}})
    },
    //代班任务
    customerQuerySubstitution(params){
      return instance.post(`/pricing-check/customer/querySubstitution`,params,{headers: {'application': 'application/json'}})
    },
    //根据客户ID查询关联车辆
    customerQueryRelatedCustomer(id){
      return instance.get(`/pricing-check/customer/queryRelatedCustomer/${id}`)
    },
    //根据客户ID查询客户详情
    customerQueryCustomerById(id){
      return instance.get(`/pricing-check/customer/queryCustomerById/${id}`)
    },
    //外勤当日排班（获取当日可以安排外勤的时间）
    checkBookTodayFieldFreeTime(params){
      return instance.post(`/pricing-check/checkBook/todayFieldFreeTime`,params)
    },
    //获取外勤预约单
    checkBookQueryFieldBook(params){
      return instance.post(`/pricing-check/checkBook/queryFieldBook`,params,{headers: {'application': 'application/json'}})
    },
    //提交外勤检测预约
    checkBookSubmit(params){
      return instance.post(`/pricing-check/checkBook/field/submit`,params,{headers: {'application': 'application/json'}})
    },
    //根据地址获取附近门店及产能（仅上午产能）
    shopQueryNearbyShop(params){
      return instance.get(`/pricing-check/shop/queryNearbyShop`,{params})
    },
    //店铺-查询所有有效门店
    shopQueryStores(){
      return instance.get(`/pricing-check/shop/queryStores`)
    },
    //预约-提交检测预约
    bookInfoSubmitBook(params){
      return instance.post(`/pricing-check/bookInfo/submitBook`,params,{headers: {'application': 'application/json'}})
    },
    //检测坐席-获取所有检测坐席
    checkSeatQueryCheckSeatList(){
      return instance.get(`/pricing-check/checkSeat/queryCheckSeatList`)
    },
    //检测坐席-检测坐席排班查询
    checkSeatQueryCheckSeatDuty(params){
      return instance.get(`/pricing-check/checkSeat/queryCheckSeatDuty`,{params})
    },
    //检测坐席-检测坐席分组查询
    checkSeatQueryCheckSeatGroupList(){
      return instance.get(`/pricing-check/checkSeat/queryCheckSeatGroupList`)
    },
    //检测坐席分组-新增或者修改组
    checkSeatSaveOrUpdateCheckSeatGroup(params){
      return instance.post(`/pricing-check/checkSeat/saveOrUpdateCheckSeatGroup?${qs.stringify(params)}`,params)
    },
    //检测坐席分组-检测坐席排班更新
    checkSeatUpdateCheckSeatDuty(params){
      return instance.post(`/pricing-check/checkSeat/updateCheckSeatDuty`,params,{headers: {'application': 'application/json'}})
      // return instance.post(`/pricing-check/checkSeat/updateCheckSeatDuty?${qs.stringify(params)}`)
    },
    //下载检测坐席排班模版表
    getDutyTemplateDownload(params){
      return instance.get(`/pricing-check/checkSeat/duty/template/download`,{params})
    },
    

    //检测坐席分组-新增检测坐席组员
    checkSeatAddGroupUsers(params){
      let str = '?groupId='+params.groupId;
      params.userIds.forEach(item => {
        str+='&userIds=';
        str+=item
      })
      return instance.post(`/pricing-check/checkSeat/addGroupUsers${str}`)
    },
    //检测坐席分组-删除组内成员
    checkSeatDeleteGroupUser(params){
      return instance.post(`/pricing-check/checkSeat/deleteGroupUser?${qs.stringify(params)}`,params)
    },
    //检测坐席分组-删除组
    checkSeatDeleteGroup(params){
      return instance.post(`/pricing-check/checkSeat/deleteGroup?${qs.stringify(params)}`,params)
    },
    //客户线索分配规则分页列表
    postCustomerAssignRulePage(params){
      return instance.post(`/pricing-check/customerAssign/rule/page`,params,{headers: {'application': 'application/json'}})
    },
    //分配规则-查询所有数据源
    getCustomerAssignSource(){
      return instance.get(`/pricing-check/customerAssign/source`)
    },
    //客户线索分配规则新增
    postCustomerAssignRuleAdd(params){
      return instance.post(`/pricing-check/customerAssign/rule/add`,params,{headers: {'application': 'application/json'}})
    },
    //删除客户线索分配规则
    postCustomerAssignRuleDelete(id,params){
      return instance.post(`/pricing-check/customerAssign/rule/delete/${id}`,params,{headers: {'application': 'application/json'}})
    },
    //客户线索分配规则新增和修改
    postCustomerAssignRuleUpdate(id,params){
      return instance.post(`/pricing-check/customerAssign/rule/update/${id}`,params,{headers: {'application': 'application/json'}})
    },
    




    //检测坐席-从Excel导入检测坐席排班模版表
    postCheckSeatDutyImport(params){
      return instance.post(`/pricing-check/checkSeat/duty/import`,params,{headers:{'application': 'multipart/form-data'}})
    },



    //获取外勤虚拟五方格
    getCheckResources(isToday,params){
      return instance.get(`/pricing-check/checkResource/fieldPlan/${isToday}`, {params})
    },
    //添加外勤虚拟五方格任务
    postFieldPlanSave(params){
      return instance.post(`/pricing-check/checkResource/fieldPlan/save`,params,{headers: {'application': 'application/json'}})
    },
    //获取外勤排班线路
    getCheckResourcesFieldRoute(isToday,params){
      return instance.get(`/pricing-check/checkResource/field/route/${isToday}`, {params})
    },
    //获取外勤资源设置
    getCheckResourcesFieldSetting(month,params){
      return instance.get(`/pricing-check/checkResource/field/setting/${month}`, {params})
    },
    //批量修改资源设置
    postCheckResourceSettingUpdateBatch(params){
      return instance.post(`/pricing-check/checkResource/setting/updateBatch`,params,{headers: {'application': 'application/json'}})
    },
    //获取门店资源设置(编辑检测师数量)
    getCheckResourceShopSetting(isToday,params){
      return instance.get(`/pricing-check/checkResource/shop/setting/${isToday}`, {params})
    },
    //获取门店五方格任务
    getCheckResourceShopTask(isToday,params){
      return instance.get(`/pricing-check/checkResource/shopTask/${isToday}`, {params})
    },
    //门店检测资源关闭或打开
    postCheckResourceShopSwitch(params){
      return instance.post(`/pricing-check/checkResource/shop/switch`,params,{headers: {'application': 'application/json'}})
    },
    //修改（取消和排班）外勤虚拟五方格任务
    postCheckResourceFieldPlanUpdate(params){
      return instance.post(`/pricing-check/checkResource/fieldPlan/update`,params,{headers: {'application': 'application/json'}})
    },
    //根据客户Id获取未取消的五方格勤虚拟任务
    getCheckResourceGetFieldPlan(customerId,params){
      return instance.get(`/pricing-check/checkResource/getFieldPlan/${customerId}`, {params})
    },
    //获取检测类型集合
    getCheckBookCheckType(params){
      return instance.post(`/pricing-check/checkBook/checkType/list`,params,{headers: {'application': 'application/json'}})
    },
    //获取取消原因集合
    getCheckBookCannelReason(params){
      return instance.get(`/pricing-check/checkBook/cannelReason/list`, {params})
    },
    //查询门店检测产能
    getCheckResourceCapacity(params){
      return instance.get(`/pricing-check/checkResource/capacity`, {params})
    },
    //提交门店检测预约
    postCheckBookShopSubmit(params){
      return instance.post(`/pricing-check/checkBook/shop/submit`,params,{headers: {'application': 'application/json'}})
    },
    //外勤检测调度
    postSchedulingFieldList(params){
      return instance.post(`/pricing-check/check/scheduling/field/list`,params,{headers: {'application': 'application/json'}})
    },
    //检测调度历史
    postSchedulingHistoryList(params){
      return instance.post(`/pricing-check/check/scheduling/history/list`,params,{headers: {'application': 'application/json'}})
    },
    
    
    

    
    
    
    




    

    
    
    //提交外勤预约回访纪录
    postCheckBookSurvey(params){
      return instance.post(`/pricing-check/checkBook/survey`,params,{headers: {'application': 'application/json'}})
    },
    //查询呼入通话记录列表【分页】
    postPageCdrIbs(params){
      return instance.post(`/pricing-check/call/pageCdrIbs?${qs.stringify(params)}`,params,{headers: {'application': 'application/json'}})
    },
    //查询外呼通话记录列表【分页】
    postPageCdrObs(params){
      return instance.post(`/pricing-check/call/pageCdrObs?${qs.stringify(params)}`,params,{headers: {'application': 'application/json'}})
    },
    //根据手机号查询最后一个客户记录
    getCustomerByPhone(phone,params){
      return instance.get(`/pricing-check/customer/getCustomerByPhone/${phone}`, {params})
    },

    //根据通话记录Id查询业务跟进Id
    getQueryFollowUpId(id,params){
      return instance.get(`/pricing-check/call/queryFollowUpId/${id}`, {params})
    },
    //获取录音播放地址
    getCallRecordPlay(uniqueid,params){
      return instance.get(`/pricing-check/call/record/play/url/${uniqueid}`, {params})
    },
    //深海捷相关接口
    //深海捷--用户登陆
    postSingheadLogin(device,extension,params){
      return instance.post(`/pricing-check/singhead/login?device=${device}&extension=${extension}`,params,{headers: {'application': 'application/json'}})
    },
    //深海捷--用户退出
    postSingheadLogout(extension,params){
      return instance.post(`/pricing-check/singhead/logout?extension=${extension}`,params,{headers: {'application': 'application/json'}})
    },
    //深海捷--深海捷电话呼入
    postSingheadCallIn(params){
      return instance.post(`/pricing-check/singhead/callIn`,params,{headers: {'application': 'application/json'}})
    },
    //深海捷--深海捷电话呼出
    postSingheadCallOut(params){
      return instance.post(`/pricing-check/singhead/callOut`,params,{headers: {'application': 'application/json'}})
    },
    //深海捷--拨打电话
    postSingheadCallPhone(params){
      return instance.post(`/pricing-check/singhead/callPhone`,params,{headers: {'application': 'application/json'}})
    },
    //深海捷--记录日志
    postSingheadLog(message,type,params){
      return instance.post(`/pricing-check/singhead/log?message=${message}&type=${type}`,params,{headers: {'application': 'application/json'}})
    },
    //深海捷--转接
    postSingheadTransfer(extension,extensionDst,params){
      return instance.post(`/pricing-check/singhead/transfer?extension=${extension}&extensionDst=${extensionDst},`,params,{headers: {'application': 'application/json'}})
    },
    //根据通话ID查询详情
    getCallRecordByMainUniqueId(params){
      return instance.get(`/pricing-check/call/getCallRecordByMainUniqueId`, {params})
    },

    /**话务-电话开始**/
    //绑定坐席电话
    postBindClientTel(params){
      return instance.post(`/pricing-check/call/bindClientTel?${qs.stringify(params)}`,params,{headers: {'application': 'application/json'}})
    },
    //外呼
    postCallout(params){
      return instance.post(`/pricing-check/call/callout?${qs.stringify(params)}`,params,{headers: {'application': 'application/json'}})
    },
    //获取拨打保密呼叫的固定手机列表
    getFixedPhone(params){
      return instance.get(`/pricing-check/customer/fixed/phone`,{params})
    },
    //根据客户Id选择保密呼叫，查询虚拟号码
    postPrivacyCallOut(params){
      return instance.post(`/pricing-check/customer/privacy/callOut?${qs.stringify(params)}`,params,{headers: {'application': 'application/json'}})
    },
    


    //查询 坐席与员工工号的绑定关系列表【坐席和绑定员工是1V1关系】
    postlistCnoEmpRela(params){
      return instance.post(`/pricing-check/call/listCnoEmpRela`,params,{headers: {'application': 'application/json'}})
    },
    //转移
    postTransfer(params){
      return instance.post(`/pricing-check/call/transfer?${qs.stringify(params)}`,params,{headers: {'application': 'application/json'}})
    },
    //解绑坐席电话
    postUnBindClientTel(params){
      return instance.post(`/pricing-check/call/unBindClientTel?${qs.stringify(params)}`,params,{headers: {'application': 'application/json'}})
    },
    //根据坐席工号查询坐席信息
    getCallDetailByCno(params){
      return instance.post(`/pricing-check/call/getCallDetailByCno?${qs.stringify(params)}`,params,{headers: {'application': 'application/json'}})
    },
  
    //静音
    postCallMute(params){
      return instance.post(`/pricing-check/call/mute?${qs.stringify(params)}`,params,{headers: {'application': 'application/json'}})
    },
    //取消静音
    postCallUnmute(params){
      return instance.post(`/pricing-check/call/unmute?${qs.stringify(params)}`,params,{headers: {'application': 'application/json'}})
    },
    //下线
    postCallOffline(params){
      return instance.post(`/pricing-check/call/offline?${qs.stringify(params)}`,params,{headers: {'application': 'application/json'}})
    },
    //上线
    postCallOnline(params){
      return instance.post(`/pricing-check/call/online?${qs.stringify(params)}`,params,{headers: {'application': 'application/json'}})
    },
    //置忙
    postCallPause(params){
      return instance.post(`/pricing-check/call/pause?${qs.stringify(params)}`,params,{headers: {'application': 'application/json'}})
    },
    //置闲
    postCallUnpause(params){
      return instance.post(`/pricing-check/call/unpause?${qs.stringify(params)}`,params,{headers: {'application': 'application/json'}})
    },
    //保持
    postCallHold(params){
      return instance.post(`/pricing-check/call/hold?${qs.stringify(params)}`,params,{headers: {'application': 'application/json'}})
    },
    //取消保持
    postCallUnHold(params){
      return instance.post(`/pricing-check/call/unHold?${qs.stringify(params)}`,params,{headers: {'application': 'application/json'}})
    },

    //云呼电话呼入
    postCustomerCallIn(params){
      return instance.post(`/pricing-check/customer/callIn?${qs.stringify(params)}`,params,{headers: {'application': 'application/json'}})
    },
    //云呼电话呼出
    postCustomerCallOut(params){
      return instance.post(`/pricing-check/customer/callOut?${qs.stringify(params)}`,params,{headers: {'application': 'application/json'}})
    },
    //手机号主次互换
    postExchangePhone(customerId,params){
      return instance.post(`/pricing-check/customer/exchangePhone/${customerId}`,params,{headers: {'application': 'application/json'}})
    },

    
    



    
    


    
    
    /**话务-电话结束**/

    

    /** 互动-end **/
    /** 管理员-begin */

    //部门-查询所有部门
    departmentQueryAllDepartments(){
      return instance.post(`/pricing-user/department/queryAllDepartments`)
    },
    //用户-根据条件查询用户信息,都不传分页查所有
    userQuery(params){
      return instance.post(`/pricing-user/user/query`,params,{headers: {'application': 'application/json'}})
    },
    //查询简单的用户列表[分页]
    getQueryUserPage(params){
      return instance.post(`/pricing-user/user/simple/queryUserPage`,params,{headers: {'application': 'application/json'}})
    },
    //角色-根据条件搜索角色信息
    roleQuery(params){
      return instance.post(`/pricing-user/role/query`,params,{headers: {'application': 'application/json'}})
    },
    postRoleList(params){
      return instance.post(`/pricing-user/role/list`,params,{headers: {'application': 'application/json'}})
    },
    //角色-修改角色信息
    roleUpdate(id,params){
      return instance.put(`/pricing-user/role/update/${id}`,params)
    },
    //部门-根据条件查询部门信息（获取门店）(查询部门【不递归查询】)
    departmentQuery(params){
      return instance.post(`/pricing-user/department/query`,params,{headers: {'application': 'application/json'}})
    },
    //查询部门【递归查询，不分页】
    postDepartmentList(params){
      return instance.post(`/pricing-user/department/list`,params,{headers: {'application': 'application/json'}})
    },
    //用户-修改用户信息
    userUpdate(id,params){
      return instance.put(`/pricing-user/user/update/${id}`,params)
    },
    //部门-修改部门信息
    departmentUpdate(id,params){
      return instance.put(`/pricing-user/department/update/${id}`,params)
    },
    //启用或者禁用【operaType：0启用，1禁用】
    postDepartmentIsEnable(deptId,operaType,params){
      return instance.post(`/pricing-user/department/isEnable/${deptId}/${operaType}`,params,{headers: {'application': 'application/json'}})
    },
    //部门-新增一个部门
    departmentAdd(params){
      return instance.post(`/pricing-user/department/add`,params,{headers: {'application': 'application/json'}})
    },
    //部门-删除部门
    departmentDelete(params){
      return instance.delete(`/pricing-user/department/delete/${params}`)
    },
    //供应商-根据条件搜索供应商信息
    supplierQuery(params){
      return instance.post(`/pricing-user/supplier/query`,params,{headers: {'application': 'application/json'}})
    },
    //供应商-根据业务类型查询业务范围
    supplierGetDictsByType(params){
      return instance.post(`/pricing-user/supplier/getDictsByType`,params,{headers: {'application': 'application/json'}})
    },
    //供应商-新增供应商
    supplierAdd(params){
      return instance.post(`/pricing-user/supplier/add`,params,{headers: {'application': 'application/json'}})
    },
    //供应商-修改供应商信息
    supplierUpdate(id,params){
      return instance.put(`/pricing-user/supplier/update/${id}`,params)
    },
    //仓库 新增
    stockStockObjAdd(params){
      return instance.post(`/pricing-trade/stock/stockObj/add`,params,{headers: {'application': 'application/json'}})
    },
    //仓库 修改
    stockStockObjUpdate(id, params){
      return instance.post(`/pricing-trade/stock/stockObj/update/${id}`,params,{headers: {'application': 'application/json'}})
    },
    //货区列表查询，如果仓库ID为空查全部
    stockStockObjAreaList(params){
      return instance.get(`/pricing-trade/stock/stockObjArea/list`,{params})
    },
    //货区 新增
    stockStockObjAreaAdd(params){
      return instance.post(`/pricing-trade/stock/stockObjArea/add`,params,{headers: {'application': 'application/json'}})
    },
    //货区 修改
    stockStockObjAreaUpdate(id, params){
      return instance.post(`/pricing-trade/stock/stockObjArea/update/${id}`,params,{headers: {'application': 'application/json'}})
    },
    //货位列表查询，如果货区ID为空查全部
    stockStockObjLocationList(params){
      return instance.get(`/pricing-trade/stock/stockObjLocation/list`,{params})
    },
    //货位 新增
    stockStockObjLocationAdd(params){
      return instance.post(`/pricing-trade/stock/stockObjLocation/add`,params,{headers: {'application': 'application/json'}})
    },


    /** 管理员-end */
    /** 门店-检测审核-begin */
    //检测审核-车型审核列表
    postAuditCarTypeList(auditStatus,params){
      return instance.post(`/pricing-check/checkReport/queryAuditCarType/${auditStatus}`,params,{headers: {'application': 'application/json'}})
    },
    //检测审核-车型审核
    postAuditCarType(id,auditStatus,params){
      return instance.post(`/pricing-check/checkReport/auditCarType/${id}?auditStatus=${auditStatus}`,params,{headers: {'application': 'application/json'}})
    },
    //获取检测报告
    getCheckReport(reportId,params){
      return instance.get(`/pricing-check/checkReport/${reportId}`,{params})
    },
    //获取检测子项信息
    getCheckItemSubsection(itemCode,params){
      return instance.get(`/pricing-check/checkReport/getCheckItemSubsection/${itemCode}`,{params})
    },
    //修改报告子项信息
    updateReportDetail(params){
      return instance.post(`/pricing-check/checkReport/updateReportDetail`,params,{headers: {'application': 'application/json'}})
    },
    //修改检测报告总结
    updateResult(id,params){
      return instance.post(`/pricing-check/checkReport/updateResult/${id}`,params,{headers: {'application': 'application/json'}})
    },
    //提醒检测师
    postReminderChecker(id,params){
      return instance.post(`/pricing-check/checkReport/reminderChecker/${id}`,params,{headers: {'application': 'application/json'}})
    },
    //车辆审核列表
    postQueryAudit(auditStatus,params){
      return instance.post(`/pricing-check/checkReport/queryAudit/${auditStatus}`,params,{headers: {'application': 'application/json'}})
    },
    //车辆报告审核
    postAuditReport(id,params){
      return instance.post(`/pricing-check/checkReport/auditReport/${id}`,params,{headers: {'application': 'application/json'}})
    },
    //检测报告审核人进行过程的状态
    postCheckAuditCourseStatus(id,params){
      return instance.post(`/pricing-check/checkReport/checkAuditCourseStatus/${id}?${qs.stringify(params)}`,params,{headers: {'application': 'application/json'}})
    },
    //进行 竞拍状态 操作【延期竞拍，暂时不拍，竞拍终止，重拍，审核通过】
    postOperaStatus(params){
      return instance.post(`/pricing-auction/auction/operStatus`,params,{headers: {'application': 'application/json'}})
    },
    //价格审核操作 发送价格/重拍
    postAuctionAuditPrice(params){
      return instance.post(`/pricing-auction/car/auction/auditPrice?${qs.stringify(params)}`,params,{headers: {'application': 'application/json'}})
    },
    //精真估 估车价
    postEvaluateCarByJingZhenGu(params){
      return instance.post(`/pricing-trade/car/evaluateCarByJingZhenGu`,params,{headers: {'application': 'application/json'}})
    },
    



    //仅仅获取所有品牌
    getBrandList(params){
      return instance.get(`/pricing-check/baseCar/brand/list`,{params})
    },
    //竞拍场次 条件查x询
    postSeatlist(seatType,params){
      return instance.get(`/pricing-auction/seat/list/${seatType}`,{params})
    },
    //竞拍场次【常规拍】 新增
    postAuctionCommonAdd(params){
      return instance.post(`/pricing-auction/seat/common/add`,params,{headers: {'application': 'application/json'}})
    },
    //竞拍场次【常规拍】 修改
    updateCommonSeat(auctionSeatId,params){
      return instance.put(`/pricing-auction/seat/common/update/${auctionSeatId}`,params)
    },
    //竞拍场次 删除
    deleteSeat(auctionSeatId){
      return instance.delete(`/pricing-auction/seat/delete/${auctionSeatId}`)
    },
    //竞拍场次【及时拍】 修改
    updateTimelySeat(params){
      return instance.post(`/pricing-auction/seat/timely/update`,params,{headers: {'application': 'application/json'}})
    },
    //竞拍场次【常规拍】 启用/暂停
    updateEnableSeat(auctionSeatId,type){
      return instance.put(`/pricing-auction/seat/common/enable/${auctionSeatId}/${type}`)
    },
    //竞拍场次设置 条件查询【不分页】（竞拍产品设置）
    postSeatRatelist(seatTypes,params){
      return instance.get(`/pricing-auction/seat/rate/${seatTypes}`,{params})
    },
    //竞拍场次设置 新增（竞拍产品设置）
    postSeatRateAdd(params){
      return instance.post(`/pricing-auction/seat/rate/update`,params,{headers: {'application': 'application/json'}})
    },
    //竞拍 查询详情
    getAuctionDetails(auctionId,params){
      return instance.get(`/pricing-auction/auction/details/${auctionId}`,{params})
    },
     //根据carId 查询详情
     getDetailsByCarId(carId,params){
      return instance.get(`/pricing-auction/auction/detailsByCarId/${carId}`,{params})
    },
    //车型排放标准 查询
    postEmissionQuery(params){
      return instance.post(`/pricing-check/baseCar/emission/query`,params,{headers: {'application': 'application/json'}})
    },
    //车型排放标准 新增
    postEmissionAdd(params){
      return instance.post(`/pricing-check/baseCar/emission/add`,params,{headers: {'application': 'application/json'}})
    },
    //导出车辆排放标准Excel
    exportCarEmission(params){
      return instance({
        url: '/pricing-check/baseCar/exportCarEmission',
        method: 'post',
        data: params,
        responseType: 'blob',
        headers: {'application': 'application/json'}
      })
    },
    //车型排放标准 删除
    deleteEmission(emissionId){
      return instance.delete(`/pricing-check/baseCar/emission/delete/${emissionId}`)
    },
    //车型排放标准 更新
    updateEmission(emissionId,params){
      return instance.put(`/pricing-check/baseCar/emission/update/${emissionId}`,params,{headers: {'application': 'application/json'}})
    },
    //从Excel导入车辆排放标准
    importCarEmissionList(params){
      return instance.post(`/pricing-check/baseCar/importCarEmissionList`,params,{headers: {'application': 'multipart/form-data'}})
    },
    
    //根据 carId和merchantId 进行 竞拍价格确认 操作【确认价格，放弃价格，置为无效】
    postAuctionConfirmPrice(params){
      return instance.post(`/pricing-auction/car/auction/confirmPrice`,params,{headers: {'application': 'application/json'}})
    },
    
    //根据 carId 查询 重拍价格确认详情
    getReAuctionDetailByCarId(carId,params){
      return instance.get(`/pricing-auction/auction/getAuctionDetailByCarId/${carId}`,{params})
    },
    //根据竞拍Id获取竞拍详情
    getAuctionInfo(carAuctionId,params){
      return instance.get(`/pricing-auction/car/auction/info/${carAuctionId}`,{params})
    },
    //根据竞拍Id获取有效价格列表
    getAuctionPriceValidList(carAuctionId,params){
      return instance.get(`/pricing-auction/car/auction/price/valid/list/${carAuctionId}`,{params})
    },
    //根据竞拍Id获取有效价格前三名列表
    getAuctionPriceThreeList(carAuctionId,params){
      return instance.get(`/pricing-auction/car/auction/price/valid/three/list/${carAuctionId}`,{params})
    },
    //根据竞拍Id获取无效价格列表
    getAuctionPriceInvalidList(carAuctionId,params){
      return instance.get(`/pricing-auction/car/auction/price/invalid/list/${carAuctionId}`,{params})
    },
    //根据竞拍Id获取最终价格列表
    getAuctionPriceFinallyList(carAuctionId,params){
      return instance.get(`/pricing-auction/car/auction/price/finally/list/${carAuctionId}`,{params})
    },
    


    
    
    //车辆 评估历史-全部权限查询
    postAssessAllList(params){
      return instance.post(`/pricing-auction/car/auction/assess/all/page`,params,{headers: {'application': 'application/json'}})
    },
    //车辆 评估历史-部分权限查询
    postAssessPartList(params){
      return instance.post(`/pricing-auction/car/auction/assess/part/page`,params,{headers: {'application': 'application/json'}})
    },
    //车辆 评估历史-受限列表查询
    postAssessNoList(params){
      return instance.post(`/pricing-auction/car/auction/assess/No/page`,params,{headers: {'application': 'application/json'}})
    },
    //车辆 评估历史-全部权限查询详情
    getAssessAllDetail(carId,params){
      return instance.get(`/pricing-trade/car/assess/all/detail/${carId}`,{params})
    },
    //车辆 评估历史-部分权限查询详情
    getAssessPartDetail(carId,params){
      return instance.get(`/pricing-trade/car/assess/part/detail/${carId}`,{params})
    },
    //车辆 评估历史-受限权限查询详情
    getAssessNoDetail(carId,params){
      return instance.get(`/pricing-trade/car/assess/no/detail/${carId}`,{params})
    },
    //车身颜色 列表查询
    getQueryCarColor(params){
      return instance.get(`/pricing-trade/car/queryCarColor`,{params})
    },
    //检测师分页查询
    postCheckerPage(params){
      return instance.post(`/pricing-check/checker/page`,params,{headers: {'application': 'application/json'}})
    },
    //复制客户 复制新建/同牌不同车/同车不同牌
    postCustomerCopyCustomer(params){
      return instance.post(`/pricing-check/customer/copyCustomer`,params,{headers: {'application': 'application/json'}})
    },
    //根据 carId 查询车辆简单详情（只包含车辆基本信息）
    getFeignCarDetail(carId,params){
      return instance.get(`/pricing-trade/feign/car/detail/${carId}`,{params})
    },
    //车辆竞拍 列表查询【分页】
    postCarAuctionPage(params){
      return instance.post(`/pricing-auction/car/auction/page`,params,{headers: {'application': 'application/json'}})
    },
    //价格确认 列表查询【分页】
    postCarConfirmPage(params){
      return instance.post(`/pricing-auction/car/auction/price/confirm/page`,params,{headers: {'application': 'application/json'}})
    },
    //车辆竞拍跟进 列表查询【分页】
    postCarFollowUpPage(params){
      return instance.post(`/pricing-auction/car/auction/price/followUp/page`,params,{headers: {'application': 'application/json'}})
    },
    

    //根据carAuctionId查询 竞拍详情
    getAuctionInfoByCarAuctionIdAndMerchantId(carAuctionId,merchantId,params){
      return instance.get(`/pricing-auction/auction/getAuctionInfoByCarAuctionIdAndMerchantId/${carAuctionId}/${merchantId}`,{params})
    },
    //根据车辆竞拍Id查询该场次的前三名商户报价【所有的报价】
    getListAllTopThreeByCarAuctionId(carAuctionId,params){
      return instance.get(`/pricing-auction/auction/listAllTopThreeByCarAuctionId/${carAuctionId}`,{params})
    },
    //检测审核工作台【包含: 车型待审核，车型审核驳回，车辆待审核，车辆审核驳回，车辆暂时不拍，车辆延期竞拍等】
    getCheckReportAuditCount(params){
      return instance.get(`/pricing-check/checkReport/audit/count`,{params})
    },
    


    
    
    
    
    /** 门店-检测审核-end */

    /** 帮卖经理-begin */

    //获取待分配列表
    queryNoAssignList(params){
      return instance.post(`/pricing-check/dealBook/queryNoAssignList`,params,{headers: {'application': 'application/json'}})
    },
    //分配帮卖经理
    assignOperator(id,trader,params){
      return instance.post(`/pricing-check/dealBook/assignOperator?id=${id}&trader=${trader}`,params,{headers: {'application': 'application/json'}})
    },
    //获取新任务列表
    queryNoFollowUpList(params){
      return instance.post(`/pricing-check/dealBook/queryNoFollowUpList`,params,{headers: {'application': 'application/json'}})
    },
    //获取跟进中列表
    queryFollowUpList(params){
      return instance.post(`/pricing-check/dealBook/queryFollowUpList`,params,{headers: {'application': 'application/json'}})
    },
    //获取沟通中列表
    queryConcernList(params){
      return instance.post(`/pricing-check/dealBook/queryConcernList`,params,{headers: {'application': 'application/json'}})
    },
    //获取预约成功列表
    queryBookSuccessList(params){
      return instance.post(`/pricing-check/dealBook/queryBookSuccessList`,params,{headers: {'application': 'application/json'}})
    },
    //获取预约失败列表
    queryBookFailList(params){
      return instance.post(`/pricing-check/dealBook/queryBookFailList`,params,{headers: {'application': 'application/json'}})
    },
    //获取商户预约状态下拉框
    getMerchantStatus(params){
      return instance.get(`/pricing-check/dealBook/merchant/getMerchantStatus`,{params})
    },
    //入库/出库对象 查询
    // logisticsQueryStockObjInOut(params){
    //   return instance.get(`/pricing-trade/stock/queryStockObjInOut`,{params})
    // },
    //入库/出库类型 查询
    logisticsQueryStockInOutType(inOutType,params){
      return instance.get(`/pricing-trade/stock/queryStockInOutType/${inOutType}`,{params})
    },
    //出库对象 查询
    getQueryStockObjOut(params){
      return instance.get(`/pricing-trade/stock/queryStockObjOut`,{params})
    },
    

    //根据 物流/库存ID（二者只是概念的区别） 查询详情以及关系
    // logisticsStockHandling(params){
    //   return instance.post(`/pricing-trade/logistics/stockHandling`,params,{headers: {'application': 'application/json'}})
    // },
    //物流 列表查询
    logisticsList(params){
      return instance.post(`/pricing-trade/logistics/list`,params,{headers: {'application': 'application/json'}})
    },
    //根据库存ID 查询详情以及关系
    logisticsDetail(id,params){
      return instance.post(`/pricing-trade/logistics/detail/${id}`,params,{headers: {'application': 'application/json'}})
    },
    //发货单 新增
    logisticsAdd(params){
      return instance.post(`/pricing-trade/logistics/add`,params,{headers: {'application': 'application/json'}})
    },
    //预警处理
    logisticsAlarmHandling(id,params){
      return instance.post(`/pricing-trade/logistics/alarmHandling/${id}`,params,{headers: {'application': 'application/json'}})
    },
    //异常处理
    logisticsExceptionHandling(id,params){
      return instance.post(`/pricing-trade/logistics/exceptionHandling/${id}`,params,{headers: {'application': 'application/json'}})
    },
    //收货(单个或者批量)，货物异常 等处理
    logisticsLogistHandling(params){
      return instance.post(`/pricing-trade/logistics/logistHandling`,params,{headers: {'application': 'application/json'}})
    },
    //车辆 列表查询
    carList(params){
      return instance.post(`/pricing-trade/car/list`,params,{headers: {'application': 'application/json'}})
    },
    //根据carId查询车辆基本信息、客户信息及材料跟进详情
    carFollowDetail(id,params){
      return instance.post(`/pricing-trade/car/follow/detail/${id}`,params,{headers: {'application': 'application/json'}})
    },
    //车辆材料(包括车主材料) 完成跟进
    postMaterialsFinish(carId,params){
      return instance.post(`/pricing-trade/car/follow/materials/finish/${carId}?${qs.stringify(params)}`,params,{headers: {'application': 'application/json'}})
    },
    //创建车辆材料跟进流水
    postFlowCarCreate(params){
      return instance.post(`/pricing-trade/car/follow/flow/car/create`,params,{headers: {'application': 'application/json'}})
    },
    //延期竞拍跟进列表
    queryExtensionAuction(params){
      return instance.post(`/pricing-check/checkReport/queryExtensionAuction`,params,{headers: {'application': 'application/json'}})
    },
    //次日检测跟进列表
    postQueryNoFollowUp(params){
      return instance.post(`/pricing-check/check/queryNoFollowUp`,params,{headers: {'application': 'application/json'}})
    },
    //次日检测跟进完成
    postFollowUpFinish(id,params){
      return instance.post(`/pricing-check/check/followUpFinish/${id}`,params,{headers: {'application': 'application/json'}})
    },
    //延期竞拍跟进完成
    postExtensionAuctionFinish(id,params){
      return instance.post(`/pricing-check/checkReport/extensionAuctionFinish/${id}`,params,{headers: {'application': 'application/json'}})
    },
    //延期竞拍
    postExtensionAuction(id,date,remark,params){
      return instance.post(`/pricing-check/checkReport/extensionAuction/${id}?date=${date}&remark=${remark}`,params,{headers: {'application': 'application/json'}})
    },
    //撤销延期竞拍
    postAuctionCancel(id,remark,params){
      return instance.post(`/pricing-check/checkReport/extensionAuction/cancel/${id}?remark=${remark}`,params,{headers: {'application': 'application/json'}})
    },
    //新增销售预案
    postAddPlan(followUpId,enquiryId,plan,params){
      return instance.post(`/pricing-check/dealBook/sale/plan/add/${followUpId}?enquiryId=${enquiryId}&plan=${plan}`,params,{headers: {'application': 'application/json'}})
    },
    //修改销售预案
    putDealBookSalePlan(id,plan,params){
      return instance.put(`/pricing-check/dealBook/sale/plan/update/${id}?plan=${plan}`,params)
    },
    //获取销售预案详情
    getDealBookSalePlan(id,params){
      return instance.get(`/pricing-check/dealBook/sale/plan/${id}`,{params})
    },
    //获取历史销售预案
    getSalePlan(followUpId,params){
      return instance.get(`/pricing-check/dealBook/sale/plan/list/${followUpId}`,{params})
    },
    //根据客户Id查询客户赔偿记录
    getIndemnitylist(customerId,params){
      return instance.get(`/pricing-check/indemnity/list/${customerId}`,{params})
    },
    //作废客户赔偿记录
    putIndemnityVoided(id,params){
      return instance.put(`/pricing-check/indemnity/voided/${id}`,params)
    },
    //查询所有赔偿原因
    getReasonlist(params){
      return instance.get(`/pricing-check/indemnity/reason/list`,{params})
    },
    //提交客户赔偿记录
    postIndemnitySubmit(params){
      return instance.post(`/pricing-check/indemnity/submit`,params,{headers: {'application': 'application/json'}})
    },
    //竞拍产品设置列表
    queryAuctionSetting(params){
      return instance.post(`/pricing-check/check/queryAuctionSetting`,params,{headers: {'application': 'application/json'}})
    },
    //竞拍产品设置
    putSetAuctionType(id,auctionType){
      return instance.put(`/pricing-check/check/setAuctionType/${id}?auctionType=${auctionType}`)
    },
    //意向金协议列表
    postIntentionMoneyList(params){
      return instance.post(`/pricing-check/intentionMoney/list`,params,{headers: {'application': 'application/json'}})
    },
    //获取意向金状态
    getIntentionMoneyState(params){
      return instance.get(`/pricing-check/intentionMoney/state`,{params})
    },
    //查询意向金详情
    getIntentionMoneyDetail(params){
      return instance.get(`/pricing-check/intentionMoney/detail`,{params})
    },
    //意向金协议 新增
    postIntentionMoneyAdd(params){
      return instance.post(`/pricing-check/intentionMoney/add`,params,{headers: {'application': 'application/json'}})
    },
    //意向金协议 更新
    updateIntentionMoney(intentionMoneyId,params){
      return instance.post(`/pricing-check/intentionMoney/update/${intentionMoneyId}`,params,{headers: {'application': 'application/json'}})
    },
    //意向金协议签订失败 改为 待签订意向金协议
    postIntentionMoneyAlter(params){
      return instance.post(`/pricing-check/intentionMoney/alter/state?${qs.stringify(params)}`,params,{headers: {'application': 'application/json'}})
    },
    //意向金协议签订成功 改为 修改重签
    postIntentionMoneyResign(params){
      return instance.post(`/pricing-check/intentionMoney/alter/resign?${qs.stringify(params)}`,params,{headers: {'application': 'application/json'}})
    },
    //帮卖经理-验车入库 分配帮卖,检测师 操作
    postSellerAllot(params){
      return instance.post(`/pricing-trade/valid/seller/allot`,params)
    },
    //获取检测预约详情
    getQueryCheckBook(checkBookId,params){
      return instance.get(`/pricing-check/checkBook/queryCheckBook/${checkBookId}`,{params})
    },
    //根据成交预约Id获取预约详情
    postDealBookDetail(id,params){
      return instance.post(`/pricing-check/dealBook/detail/${id}`,params,{headers: {'application': 'application/json'}})
    },
    //成交预约/改约
    postSubmitBook(params){
      return instance.post(`/pricing-check/dealBook/submitBook`,params,{headers: {'application': 'application/json'}})
    },
    //预约成功之后, 取消成交, 传递预约成交id
    postDealBookCancel(dealBookId,params){
      return instance.put(`/pricing-check/dealBook/cancel/${dealBookId}`,params,{headers: {'application': 'application/json'}})
    },
    //预约类型修改
    postDealTypeUpdate(dealBookId,dealType,params){
      return instance.post(`/pricing-check/dealBook/dealType/update/${dealBookId}?dealType=${dealType}`,params,{headers: {'application': 'application/json'}})
    },
    //修改价格有效期
    postPriceFailureTime(dealBookId,priceFailureTime,params){
      return instance.post(`/pricing-check/dealBook/priceFailureTime/update/${dealBookId}?priceFailureTime=${priceFailureTime}`,params,{headers: {'application': 'application/json'}})
    },
    //获取沟通记录
    getDealBookComm(dealBookId,params){
      return instance.get(`/pricing-check/dealBook/comm/list/${dealBookId}`,{params})
    },
    //查询门店成交产能
    queryDealCapacity(params){
      return instance.get(`/pricing-check/dealBook/capacity`,{params})
    },
    //取消检测预约
    updateCheckBookCancel(params){
      return instance.post(`/pricing-check/checkBook/cancel`,params,{headers: {'application': 'application/json'}})
    },
    //待分配检测师列表
    postQueryNoAssign(params){
      return instance.post(`/pricing-check/check/queryNoAssign`,params,{headers: {'application': 'application/json'}})
    },
    //已分配检测师列表
    postQueryNoAssigned(params){
      return instance.post(`/pricing-check/check/queryAssigned`,params,{headers: {'application': 'application/json'}})
    },
    //给指定检测师分配检测任务
    postAssignChecker(id,checkerId,params){
      return instance.post(`/pricing-check/check/assignChecker/${id}?checkerId=${checkerId}`,params,{headers: {'application': 'application/json'}})
    },
    //根据业务跟进ID查询客户概况
    getCustomerRecordList(followUpInfoId,type,params){
      return instance.get(`/pricing-check/customer/record/list/${followUpInfoId}?type=${type}`,{params})
    },
    //获取跟进中客户
    postQueryFollowingUp(params){
      return instance.post(`/pricing-check/customer/queryFollowingUp`,params,{headers: {'application': 'application/json'}})
    },
    //帮卖经理检测预约 获取跟进中客户信息
    postCheckBookQueryFollowingUp(params){
      return instance.post(`/pricing-check/customer/checkBook/queryFollowingUp`,params,{headers: {'application': 'application/json'}})
    },
    //查询车辆历史成交信息
    getListTradedCarHistory(params){
      return instance.post(`/pricing-trade/official/traded/history/page`,params,{headers: {'application': 'application/json'}})
    },
    //根据客户编号查询历史竞拍信息
    getAuctionList(customerNo,params){
      return instance.get(`/pricing-auction/car/auction/list/${customerNo}`,{params})
    },
    
    //批量发送检测调度分配检测任务
    postAssignCheckerBatch(checkerId,params){
      return instance.post(`/pricing-check/check/assignChecker/batch/${checkerId}`,params,{headers: {'application': 'application/json'}})
    },
    //验车 列表查询
    postValidList(params){
      return instance.post(`/pricing-trade/valid/page`,params,{headers: {'application': 'application/json'}})
    },
    //根据仓库名称查询详情查询
    getStockObjDetail(stockObjName,params){
      return instance.get(`/pricing-trade/stock/stockObj/detailByName/${stockObjName}`,{params})
    },
    //渠道检测任务取消
    postTaskChannel(params){
      return instance.post(`/pricing-check/check/channel/task/cancel`,params,{headers: {'application': 'application/json'}})
    },
    //渠道检测任务分配检测师列表 包含检测单
    getChannelTaskCheckerList(params){
      return instance.get(`/pricing-check/check/channel/task/checker/list`,{params})
    },
    //根据询价 Id（车辆竞拍 Id）查询是否存在未结束的合同
    getExistValidContractByCarAuctionId(carAuctionId,params){
      return instance.get(`/pricing-trade/contract/customer/existValidContractByCarAuctionId/${carAuctionId}`,{params})
    },
    //根据竞拍Id获取寄售详情
    getConsignSaleInfo(carAuctionId,params){
      return instance.get(`/pricing-auction/consign/sale/info/${carAuctionId}`,{params})
    },
    //新增车辆寄售信息
    postConsignSaleCreate(params){
      return instance.post(`/pricing-auction/consign/sale/create`,params,{headers: {'application': 'application/json'}})
    },
    //发送降价通知
    postConsignSaleSendPrice(params){
      return instance.post(`/pricing-auction/consign/sale/send/price/notice`,params,{headers: {'application': 'application/json'}})
    },
    //终止寄售
    postConsignSaleStop(id,params){
      return instance.post(`/pricing-auction/consign/sale/stop/${id}`,params,{headers: {'application': 'application/json'}})
    },
    //修改价格
    putConsignSaleUpdate(id,params){
      return instance.put(`/pricing-auction/consign/sale/price/update/${id}?${qs.stringify(params)}`)
    },
    //根据车辆竞拍Id和商户Id获取商户预约Id
    getDealBookMerchantId(enquiryId,merchantId,params){
      return instance.get(`/pricing-check/dealBook/merchant/getDealBookMerchantId/${enquiryId}/${merchantId}`,{params})
    },
    




    


    

    
    

    



    
    
    

    


    /** 帮卖经理-end */
    /** 门店店长-begin */
    //搜索门店
    getShopList(params){
      return instance.post(`/pricing-user/shop/query`,params,{headers: {'application': 'application/json'}})
    },
    //修改门店
    putShopList(id,params){
      return instance.put(`/pricing-user/shop/update/${id}`,params,{headers: {'application': 'application/json'}})
    },
    //生效/作废 门店
    getShopEnable(id,params){
      return instance.get(`/pricing-user/shop/enable/${id}`,{params})
    },

    //门店店长-差异评定 查询列表
    postValidEvaluatePage(params){
      return instance.post(`/pricing-trade/valid/evaluate/page`,params,{headers: {'application': 'application/json'}})
    },
    //材料出入库流水 查询
    shopEvaluateDetail(id){
      return instance.get(`/pricing-trade/valid/shop/evaluate/detail/${id}`)
    },
    //门店店长-差异评定 新增
    shopEvaluateAdd(params){
      return instance.post(`/pricing-trade/valid/shop/evaluate/add`,params,{headers: {'application': 'application/json'}})
    },
    //差异评定 查询详情
    getValidEvaluateDetail(evaluateId){
      return instance.get(`/pricing-trade/valid/evaluate/detail/${evaluateId}`)
    },
    //差异评定(提交差异)
    postValidDiffEvaluate(evaluateId,params){
      return instance.post(`/pricing-trade/valid/diff/evaluate/${evaluateId}`,params,{headers: {'application': 'application/json'}})
    },
    //差异确认
    postValidDiffConfirm(evaluateId,params){
      return instance.post(`/pricing-trade/valid/diff/confirm/${evaluateId}`,params,{headers: {'application': 'application/json'}})
    },
    //商户验车 查询详情
    getValidDetail(carValidId){
      return instance.get(`/pricing-trade/valid/detail/${carValidId}`)
    },
    // 商户差异复检评定列表
    postValidMerchantEvaluateList(carId,params){
      return instance.post(`/pricing-trade/valid/merchant/evaluate/list/${carId}`,params,{headers: {'application': 'application/json'}})
    },
    //确认是否成交
    postValidTradeConfirm(carValidId,isTrade,params){
      return instance.post(`/pricing-trade/valid/trade/confirm/${carValidId}/${isTrade}`,params,{headers: {'application': 'application/json'}})
    },
    //文员-外勤调度待分配
    getSchedulingFieldCount(params){
      return instance.get(`/pricing-check/check/scheduling/field/count`,{params})
    },
   
    




    
    /** 门店店长-end */
    /** 检测师主管-begin */

    //检测师主管-检测师排班查询
    checkerQueryCheckerDuty(params){
      return instance.get(`/pricing-check/checker/queryCheckerDuty`,{params})
    },
    //检测师主管-检测师查询
    checkerQueryChecker(params){
      return instance.get(`/pricing-check/checker/queryChecker`,{params})
    },
    //检测师主管-导入钉钉检测师排班
    checkerImportDingCheckerDayDuty(params){
      return instance.post(`/pricing-check/checker/importDingCheckerDayDuty`,params)
    },
    //检测师主管-检测师排班更新
    checkerUpdateCheckerDuty(params){
      return instance.post(`/pricing-auction/checker/updateCheckerDuty`,params)
    },
    //检测师主管-查询所有有效门店
    auctionStoreQueryStores(params){
      return instance.get(`/pricing-auction/store/queryStores`,{params})
    },
    //检测师主管-门店预排班自动排班
    shopAutoScheduling(params){
      return instance.post(`/pricing-auction/shop/autoScheduling`,params)
    },
    //检测师主管-查询门店排班表
    shopQuerySchedule(params){
      return instance.get(`/pricing-auction/shop/querySchedule`,{params})
    },
    //检测师主管-查询次日排班产能
    nextDayQueryScheduleCapacity(params){
      return instance.get(`/pricing-auction/shop/nextDay/queryScheduleCapacity`,{params})
    },
    //检测师主管-删除某天检测师排班
    shopDeleteCheckerSchedule(params){
      return instance.post(`/pricing-auction/shop/deleteCheckerSchedule`,params)
    },
    //检测师主管-门店预排班指派检测师
    shopAssignChecker(params){
      let str = `?checkerId=${params.checkerId}&shopNo=${params.shopNo}`;
      params.assignDates.forEach(item => {
        str+='&assignDates=';
        str+=item
      })
      return instance.post(`/pricing-auction/shop/assignChecker${str}`)
    },
    //检测师主管-次日排班空闲检测师查询
    checkerQueryLeisureChecker(params){
      return instance.get(`/pricing-auction/checker/queryLeisureChecker`,{params})
    },
    //检测师主管-次日排班产能指派检测师
    nextDayAssignChecker(params){
      return instance.post(`/pricing-auction/shop/nextDay/assignChecker`,params)
    },
    //检测师主管-查询次日候补产能
    nextDayQueryBackupCapacity(params){
      return instance.get(`/pricing-auction/shop/nextDay/queryBackupCapacity`,{params})
    },
    //检测师主管-查询检测师组
    checkerQueryCheckerGroupList(params){
      return instance.get(`/pricing-auction/checker/queryCheckerGroupList`,{params})
    },
    //检测师主管-新增检测师组员
    checkerAddGroupUsers(params){
      return instance.post(`/pricing-auction/checker/addGroupUsers`,params)
    },
    //检测师主管-删除组内成员
    checkerDeleteGroupUser(params){
      return instance.post(`/pricing-auction/checker/deleteGroupUser`,params)
    },

    /** 检测师主管-end */
    /** 销售助理-begin */

    //商户预约-获取待预约列表
    merchantQueryNoBookList(params){
      return instance.post(`/pricing-check/dealBook/merchant/queryNoBookList`,params,{headers: {'application': 'application/json'}})
    },
    //商户预约-获取跟进中列表
    merchantQueryFollowUpList(params){
      return instance.post(`/pricing-check/dealBook/merchant/queryFollowUpList`,params,{headers: {'application': 'application/json'}})
    },
    //商户预约-获取沟通中列表
    merchantQueryConcernList(params){
      return instance.post(`/pricing-check/dealBook/merchant/queryConcernList`,params,{headers: {'application': 'application/json'}})
    },
    //商户预约-获取预约成功列表
    merchantQueryBookSuccessList(params){
      return instance.post(`/pricing-check/dealBook/merchant/queryBookSuccessList`,params,{headers: {'application': 'application/json'}})
    },
    //商户预约-获取预约失败列表
    merchantQueryBookFailList(params){
      return instance.post(`/pricing-check/dealBook/merchant/queryBookFailList`,params,{headers: {'application': 'application/json'}})
    },
    //商户预约-成交预约
    merchantSubmitBook(params){
      return instance.post(`/pricing-check/dealBook/merchant/submitBook`,params,{headers: {'application': 'application/json'}})
    },
    //根据商户成交预约Id获取商户预约详情
    getDealBookMerchantDetail(id,params){
      return instance.post(`/pricing-check/dealBook/merchant/detail/${id}`,params,{headers: {'application': 'application/json'}})
    },
    //商户确认报价
    postConfirmPrice(params){
      return instance.post(`/pricing-check/dealBook/merchant/confirmPrice`,params,{headers: {'application': 'application/json'}})
    },
    
    

    //客户/商户 补偿申请查看申请
    merchantQueryDefault(params){
      return instance.get(`/pricing-check/dealBook/merchant/queryDefault/${params.dealBookId}/${params.defaultParty}`)
    },
    //客户/商户 补偿申请
    merchantSubmitDefault(params){
      return instance.post(`/pricing-check/dealBook/merchant/submitDefault`,params,{headers: {'application': 'application/json'}})
    },
    //根据条件查询 商户合同 列表
    getMerchantList(params){
      return instance.post(`/pricing-trade/contract/merchant/list`,params,{headers: {'application': 'application/json'}})
    },
    //自动计算委托收购价格
    getMerchantPay(contractId,params){
      return instance.post(`/pricing-trade/contract/merchant/calculate/pay/${contractId}`,params,{headers: {'application': 'application/json'}})
    },
    //删除/作废 商户合同
    deleteMerchant(contractId,params){
      return instance.put(`/pricing-trade/contract/merchant/delete/${contractId}`,params,{headers: {'application': 'application/json'}})
    },
    //根据 合同id 查询合同详情以及关系
    getMerchantDetail(contractId){
      return instance.get(`/pricing-trade/contract/merchant/detail/${contractId}`)
    },
    //更新 商户合同
    updateMerchant(contractId,params){
      return instance.post(`/pricing-trade/contract/merchant/update/${contractId}`,params,{headers: {'application': 'application/json'}})
    },
    //客户合同签订失败商户处理
    updateContractMerchant(params){
      return instance.put(`/pricing-trade/contract/merchant/update`,params,{headers: {'application': 'application/json'}})
    },
    // 根据 商户合同id 发送短信
    postMerchantSendMsg(contractId,params){
      return instance.post(`/pricing-trade/contract/merchant/send/msg/${contractId}`,params,{headers: {'application': 'application/json'}})
    },
   
    // 商户合同签订失败
    postMerchantSign(contractId,params){
      return instance.post(`/pricing-trade/contract/merchant/sign/fail/${contractId}`,params,{headers: {'application': 'application/json'}})
    },
    //根据 合同id 设置合同为已发送状态
    postMerchantSend(contractId,params){
      return instance.post(`/pricing-trade/contract/merchant/send/${contractId}`,params,{headers: {'application': 'application/json'}})
    },
    //办证公司 列表查询
    // postCompanyList(params){
    //   return instance.post(`/pricing-trade/certificate/company/list`,params,{headers: {'application': 'application/json'}})
    // },
    //交付中心-商户验车，验车操作，包含 【分配检测师 商户验车-发送看车单, 商户验车-远程查验-检测师领取验车检测任务, 商户验车-本地查验-商户领取完钥匙, 商户验车完成, 商户取消验车】 等操作
    postValidCarValid(params){
      return instance.post(`/pricing-trade/valid/car/valid?${qs.stringify(params)}`)
    },
    //交付中心-商户验车 查询商户看车单详情
    getSendDetail(carId){
      return instance.get(`/pricing-trade/valid/merchant/send/detail/${carId}`)
    },
    //交付中心-商户验车 查询差异详情
    getDiffDetail(carId){
      return instance.get(`/pricing-trade/valid/merchant/diff/detail/${carId}`)
    },
    //交付中心-商户验车 提差异
    postSubmitDiff(params){
      return instance.post(`/pricing-trade/valid/merchant/submitDiff`,params,{headers: {'application': 'application/json'}})
    },
    //门店店长-差异评定 根据carID查询详情
    getDetailByCarId(carId){
      return instance.get(`/pricing-trade/valid/shop/evaluate/detailByCarId/${carId}`)
    },
    //复检任务列表
    postRecheckTaskList(params){
      return instance.post(`/pricing-check/check/recheckTask/list`,params,{headers: {'application': 'application/json'}})
    },
    //获取复检报告
    getRecheckReport(params){
      return instance.get(`/pricing-check/checkReport/getRecheckReport`,{params})
    },
    //根据 车辆ID 查询验车详情
    getValidCarDetail(carId,params){
      return instance.get(`/pricing-trade/valid/car/detail/${carId}`,{params})
    },
    //库存车重拍
    postStockCarReAuction(params){
      return instance.post(`/pricing-trade/car/stockCarReAuction`,params,{headers: {'application': 'application/json'}})
    },
    //库存车退车
    getStockCarRetreat(carId,params){
      return instance.get(`/pricing-trade/car/stockCarRetreat/${carId}`,{params})
    },
    //根据 合同ID 查询商户违约需赔偿车开新金额
    getCompMoneyMerchant(contractId,breakType,params){
      return instance.get(`/pricing-trade/contract/merchant/getCompMoney/${contractId}/${breakType}`,{params})
    },
    //根据车ID查询是否必须是商户自理【true 必须是商户自理，false 自理和代办都可】
    getCertSelfValid(carId,params){
      return instance.get(`/pricing-trade/merchant/certSelf/valid/${carId}`,{params})
    },
    //计算商户预约信息中的总价
    postCalculateExpenses(merchantDealBookId,params){
      return instance.post(`/pricing-check/dealBook/merchant/calculateExpenses/${merchantDealBookId}`,params,{headers: {'application': 'application/json'}})
    },
    //手动置顶商户待预约记录
    postManuallyOnTop(params){
      return instance.post(`/pricing-auction/car/auction/manuallyOnTop`,params,{headers: {'application': 'application/json'}})
    },
    //分页查询要修改办证类型的商户合同列表
    postAlterCertTypePage(params){
      return instance.post(`/pricing-trade/contract/merchant/page/alter/certType`,params,{headers: {'application': 'application/json'}})
    },
    //修改办证类型
    postMerchantAlterCertType(params){
      return instance.post(`/pricing-trade/contract/merchant/alter/certType?${qs.stringify(params)}`,params,{headers: {'application': 'application/json'}})
    },



    

    /** 销售助理-end */



    /**商户部**开始**/
    //商户首页业务任务数量
    getHomePage(){
      return instance.get(`/pricing-trade/workbench/merchant/count`)
    },
    //签约商户列表
    getSignedList(params){
      return instance.post(`/pricing-trade/merchant/signed/list`,params,{headers: {'application': 'application/json'}})
    },
    //体验商户列表 条件分页查询
    getExperienceList(params){
      return instance.post(`/pricing-trade/merchant/experience/list`,params,{headers: {'application': 'application/json'}})
    },
    // 根据level递归获取行政地区,含级联数据【level：0-查询全部级别；1-查询省；2-查询省市；3-查询省市区】
    regionGetRecursionRegionByLevel(id){
      return instance.post(`/pricing-user/region/getRecursionRegionByLevel/${id}`,{headers: {'application': 'application/json'}})
    },
    // 取得行政地区列表,不含级联数据,【level不填查询全部级别；0-查询全部级别；1-查询省；2-查询市；3-查询区】
    getRegionByConditions(params){
      return instance.post(`/pricing-user/region/getRegionByConditions`,params,{headers: {'application': 'application/json'}})
    },
    //体验商户增加有效期
    putExperienceDelay(merchantId,params){
      return instance.put(`/pricing-trade/merchant/experience/delay/${merchantId}`,params)
    },
    //体验商户更换维护业务员
    putUpdateMaintainer(merchantId,params){
      return instance.put(`/pricing-trade/merchant/experience/updateMaintainer/${merchantId}?${qs.stringify(params)}`)
    },
    //人工认证商户列表
    postAuthList(params){
      return instance.post(`/pricing-trade/merchant/auth/list`,params,{headers: {'application': 'application/json'}})
    },
    //商户详情
    getMerchantDetails(merchantId){
      return instance.get(`/pricing-trade/merchant/details/${merchantId}`)
    },
    //人工认证审核
    postManualAudit(merchantId,params){
      return instance.post(`/pricing-trade/merchant/auth/manualAudit/${merchantId}?${qs.stringify(params)}`)
    },
    //认证驳回商户列表
    postAuthFailList(params){
      return instance.post(`/pricing-trade/merchant/auth/fail/list`,params,{headers: {'application': 'application/json'}})
    },
    //认证通过商户列表
    postAuthAssList(params){
      return instance.post(`/pricing-trade/merchant/auth/pass/list`,params,{headers: {'application': 'application/json'}})
    },
    //商户设置保证金
    postSetMargin(params){
      return instance.post(`/pricing-trade/merchant/setMargin`,params,{headers: {'application': 'application/json'}})
    },
    //代收保证金商户列表
    postAgencyFundList(params){
      return instance.post(`/pricing-trade/merchant/agencyFund/list`,params,{headers: {'application': 'application/json'}})
    },
    //保证金交完后未签约商户列表（待签约）
    postNoSignedList(params){
      return instance.post(`/pricing-trade/merchant/noSigned/list`,params,{headers: {'application': 'application/json'}})
    },
    //签约商户信息修改
    updateSigningMerchant(params){
      return instance.put(`/pricing-trade/merchant/update`,params)
    },
    //签约商户停止/开启竞拍
    putSwitchAuction(merchantId,params){
      return instance.put(`/pricing-trade/merchant/auction/switch/${merchantId}?${qs.stringify(params)}`,)
    },
    //签约商户解约
    putDissolution(merchantId,params){
      return instance.put(`/pricing-trade/merchant/dissolution/${merchantId}?${qs.stringify(params)}`)
    },
    //分页查询商户退款
    postRefundList(params){
      return instance.post(`/pricing-trade/merchant/refund/query`,params,{headers: {'application': 'application/json'}})
    },
    //根据商户退款ID查询详情
    getRefundDetailsByRefundIdMerchant(merchantRefundId,params){
      return instance.get(`/pricing-trade/merchant/refund/getRefundDetailsByRefundId/${merchantRefundId}`,{params})
    },
    //商户退款 新增
    postRefundAdd(params){
      return instance.post(`/pricing-trade/merchant/refund/add`,params,{headers: {'application': 'application/json'}})
    },
    //商户退款 修改
    postRefundUpdate(merchantRefundId,params){
      return instance.post(`/pricing-trade/merchant/refund/update/${merchantRefundId}`,params,{headers: {'application': 'application/json'}})
    },
    //根据商户账号查询商户银行账号
    getAccountDetailsByMerchantNo(merchantNo){
      return instance.get(`/pricing-trade/merchant/refund/getAccountDetailsByMerchantNo/${merchantNo}`)
    },
    //根据车Id查询该场次的前三名商户报价【已确认的报价】
    getListTopThreeByCarId(carId){
      return instance.get(`/pricing-auction/auction/listTopThreeByCarId/${carId}`)
    },
    //工作台【包含: 验车，合同，收发货，差异评定，库存车，车辆钥匙，价格确认等】
    getWorkbench(params){
      return instance.get(`/pricing-trade/workbench/workbench`,{params})
    },
    //根据carId查询三方结算列表
    getThirdClearList(carId,params){
      return instance.get(`/pricing-trade/certificate/finance/third/clear/list/${carId}`,{params})
    },
    //商户办证自理 分页查询
    postCertSelfPage(params){
      return instance.post(`/pricing-trade/merchant/certSelf/page`,params,{headers: {'application': 'application/json'}})
    },
    //商户办证自理 新增
    postCertSelfAdd(params){
      return instance.post(`/pricing-trade/merchant/certSelf/add`,params,{headers: {'application': 'application/json'}})
    },
    //商户办证自理 删除
    postCertSelfDelete(params){
      return instance.post(`/pricing-trade/merchant/certSelf/delete?${qs.stringify(params)}`,params,{headers: {'application': 'application/json'}})
    },
    //商户修改办证自理资格有效期
    postCertSelfUpdateEnableEndDate(params){
      return instance.post(`/pricing-trade/merchant/certSelf/update/enableEndDate`,params,{headers: {'application': 'application/json'}})
    },
    

    
    //新增公告(竞价宝)
    postMessageNoticeAdd(params){
      return instance.post(`/pricing-auction/app/message/notice/add`,params,{headers: {'application': 'application/json'}})
    },

    

















    //根据银行简称查询银行简称
    // queryShortBankByName(params){
    //   return instance.get('/pricing-auction/manage/queryShortBankByName',{params})
    // },
    
    //根据银行简称查询银行支行
    // querySubBranchBankByName(params){
    //   return instance.get('/pricing-auction/manage/querySubBranchBankByName',{params})
    // },
    
    //根据编号查询经营市场(无参数查所有)(交易市场)
    // queryBussinessCity(params){
    //   return instance.get('/pricing-auction/manage/queryBussinessCity',{params})
    // },
    //根据办证公司编号查询办证公司
    queryBzComByComNo(params){
      return instance.get('/pricing-auction/manage/queryBzComByComNo',{params})
    },

    //根据查询条件查询在线商户列表
    queryOnlineMerchantInfoLst(params){
      return instance.get('/pricing-auction/manage/queryOnlineMerchantInfoLst',{params})
    },
    
    /**商户部**结束**/


    /**渠道部**开始**/
    //查询所有审核任务状态
    queryAllCheckTaskStatus(){
      return instance.post('/pricing-trade/channel/queryAllCheckTaskStatus')
    },
    //查询渠道状态及数量
    getChannelQueryStatus(params){//状态：1:待审核，2:审核通过，3审核驳回，4审核失败，5待签约，6已签约，7签约失败，8已解约，9佣金待审核，11佣金审核通过，12钱包提现待审核，13钱包提现审核成功，14钱包提现审核失败，15违约赔偿待审核，16违约赔偿审核成功
      return instance.post('/pricing-trade/channel/queryStatus',params,{headers: {'application': 'application/json'}})
    },
    //根据条件查询渠道审核任务列表
    getChannelPage(params){//数据状态 0-待审核 1-审核通过(待签约) 2-审核驳回 3-已签约 4-已解约 5-签约失败 6-已解约 7-已过期
      return instance.post('/pricing-trade/channel/page',params,{headers: {'application': 'application/json'}})
    },
    //根据条件查询渠道简单信息(分页, 没有渠道联系人信息, 没有角色权限信息限制)
    getPageNoRole(params){//数据状态 0-待审核 1-审核通过(待签约) 2-审核驳回 3-已签约 4-已解约 5-签约失败 6-已解约 7-已过期
      return instance.post('/pricing-trade/channel/pageNoRole',params,{headers: {'application': 'application/json'}})
    },
    //根据条件查询渠道[不分页]
    postChannelList(params){
      return instance.post('/pricing-trade/channel/list',params,{headers: {'application': 'application/json'}})
    },
    //根据账户审核条件查询渠道(分页)
    postPageByAccountStatus(params){
      return instance.post('/pricing-trade/channel/pageByAccountStatus',params,{headers: {'application': 'application/json'}})
    },
    //操作临时账号
    postOperaTempAccount(params){
      return instance.post('/pricing-trade/channel/operaTempAccount',params,{headers: {'application': 'application/json'}})
    },
    //账号信息审核通过
    postAccountAuditSuccess(params){
      return instance.post('/pricing-trade/channel/accountAuditSuccess',params,{headers: {'application': 'application/json'}})
    },
    //根据渠道ID查询(详情)
    getByChannelIdDetail(id){
      return instance.get(`/pricing-trade/channel/getByChannelId/${id}`)
    },
    //修改渠道详情内容
    updateChannelDetail(id,params){
      return instance.post(`/pricing-trade/channel/update/${id}`,params,{headers: {'application': 'application/json'}})
    },
    //修改渠道状态
    updateChannelStatus(id,status,remark,params){
      return instance.post(`/pricing-trade/channel/status/update/${id}/${status}?remark=${remark}`,params,{headers: {'application': 'application/json'}})
    },
    //获取检测管理列表
    getChannePage(params){
      return instance.post(`/pricing-check/check/channel/page`,params,{headers: {'application': 'application/json'}})
    },
    
    //给指定检测师分配检测任务
    postChanneCoordinate(id,checkerId,params){
      return instance.post(`/pricing-check/check/assignChecker/${id}?checkerId=${checkerId}`,params,{headers: {'application': 'application/json'}})
    },
    //钱包提现
    postChannelWalletWithdraw(params){
      return instance.post('/pricing-trade/channel/wallet/withdraw',params,{headers: {'application': 'application/json'}})
    },
    //钱包充值
    postChannelWalletTopUp(params){
      return instance.post('/pricing-trade/channel/wallet/topUp',params,{headers: {'application': 'application/json'}})
    },
    //查询钱包提现记录（分页）
    postChannelWithdrawPage(params){
      return instance.post('/pricing-trade/channel/withdraw/page',params,{headers: {'application': 'application/json'}})
    },
    //根据渠道ID查询渠道账户信息
    getByChannelAccountList(channelId){
      return instance.get(`/pricing-trade/channel/account/list/${channelId}`)
    },
    //违约赔偿审核列表(分页)
    postChannelDefaultPage(params){
      return instance.post('/pricing-trade/channel/default/page',params,{headers: {'application': 'application/json'}})
    },
    //查询钱包提现状态列表
    getWithdrawStatus(params){
      return instance.get('/pricing-trade/channel/withdraw/status/list',{params})
    },
    //根据审核任务Id获取审核任务详情
    getChannelAuditTask(id,params){
      return instance.get(`/pricing-trade/channel/auditTask/${id}`,{params})
    },
    //钱包提现审核
    postChannelWithdrawAudit(id,status,remark,params){
      return instance.post(`/pricing-trade/channel/withdraw/audit/${id}/${status}?remark=${remark}`,params,{headers: {'application': 'application/json'}})
    },
    //提现-更新审核任务
    postWalletWithdrawUp(auditTaskId,params){
      return instance.post(`/pricing-trade/channel/wallet/withdraw/up/${auditTaskId}`,params,{headers: {'application': 'application/json'}})
    },
    //查询钱包流水记录（分页）
    postChannelWalletFlowPage(params){
      return instance.post('/pricing-trade/channel/walletFlow/page',params,{headers: {'application': 'application/json'}})
    },
    //查询钱包流水类型列表
    getWalletFlowTypeList(params){
      return instance.get('/pricing-trade/channel/walletFlowType/list',{params})
    },
    //查询审核违约类型列表
    getLossGivenTypes(params){
      return instance.get('/pricing-trade/channel/defaultType/list',{params})
    },
    //查询违约审核状态列表
    getLossGivenCheckStatus(params){
      return instance.get('/pricing-trade/channel/defaultType/status/list',{params})
    },
    //违约赔偿审核(通过)
    postChannelDefaultAudit(id,params){
      return instance.post(`/pricing-trade/channel/default/audit/${id}`,params,{headers: {'application': 'application/json'}})
    },
    //根据渠道ID查询更新记录
    getRecordList(id,params){
      return instance.get(`/pricing-trade/channel/update/record/list/${id}`,{params})
    },
    //获取渠道车辆信息
    postCustomerChannelPage(params){
      return instance.post(`/pricing-check/customer/channel/page`,params,{headers: {'application': 'application/json'}})
    },
    //导出渠道车辆信息Excel
    postCustomerChannelexport(params){
      return instance({
        url: `/pricing-check/customer/channel/export`,
        method: 'post',
        data: params,
        responseType: 'blob',
        headers: {'application': 'application/json'}
      })
    },
    //商户违约条件查询[分页]
    postBreakAppointPage(params){
      return instance.post(`/pricing-trade/merchant/breakAppoint/page`,params,{headers: {'application': 'application/json'}})
    },
    //商户申诉
    getBreakAppointAppeal(params){
      return instance.post(`/pricing-trade/merchant/breakAppoint/appeal`,params,{headers: {'application': 'application/json'}})
    },
    //商户保单分页查询
    postPageMerchantInsure(params){
      return instance.post(`/pricing-trade/insure/merchant/pageMerchantInsure`,params,{headers: {'application': 'application/json'}})
    },
    //根据商户ID查询商户保单列表
    getListByMerchantId(params){
      return instance.get(`/pricing-trade/insure/merchant/listByMerchantId`,{params})
    },





    
    
    /**渠道部**结束**/

    /**办证专员**开始**/
    //办证 列表查询【分页】
    getCertificateTaskPage(params){
      return instance.post('/pricing-trade/certificate/task/page',params,{headers: {'application': 'application/json'}})
    },
    //办证申请操作【办证专员确认申请 apply_confirm，确认申请并邀约客户 apply_invite，退车 apply_return_car，办证公司申请 com_apply】
    postCertificateTaskOpera(params){
      return instance.post('/pricing-trade/certificate/task/opera/apply',params,{headers: {'application': 'application/json'}})
    },
    //办证 详情查询
    getCertificateTaskDetails(taskId,params){
      return instance.post(`/pricing-trade/certificate/task/details/${taskId}`,params,{headers: {'application': 'application/json'}})
    },
    //办证材料跟踪 新增
    postTraceMaterialAdd(params){
      return instance.post(`/pricing-trade/certificate/trace/material/add`,params,{headers: {'application': 'application/json'}})
    },
    //办证材料跟踪 完成
    postTraceMaterialFinish(traceId,params){
      return instance.post(`/pricing-trade/certificate/trace/material/finish/${traceId}`,params,{headers: {'application': 'application/json'}})
    },
    //办证邀约操作【继续跟进 invite_continue，邀约成功 invite_success,退车 invite_return_car】
    postTaskOperaInvite(params){
      return instance.post(`/pricing-trade/certificate/task/opera/invite`,params,{headers: {'application': 'application/json'}})
    },
    //办证过程管理操作【办证专员继续跟进 process_continue，办证专员过户完成 process_success, 办证专员退车 apply_return_car，办证公司继续跟进 com_process_continue，办证公司办证完成 com_process_success, 办证公司上传查验单 com_process_upload】
    postTaskOperaProcess(params){
      return instance.post(`/pricing-trade/certificate/task/opera/process`,params,{headers: {'application': 'application/json'}})
    },
    //办证过程管理操作【办证公司上传查验单 com_process_upload】
    postTaskOperaProcessUpload(params){
      // 车开新提供接口5：办证公司上传查验单
      return instance.post(`/pricing-trade/cert/ckx/comProcessUpload`,params,{headers: {'application': 'application/json'}})
      // return instance.post(`/pricing-trade/certificate/task/opera/process/upload`,params,{headers: {'application': 'application/json'}})
    },
    //凭证费用审核操作【保存 voucher_save, 审核通过 voucher_check_success, 审核驳回 voucher_check_reject, 办证公司申请结算 com_clear_apply】
    postTaskOperaVoucher(params){
      return instance.post(`/pricing-trade/certificate/task/opera/voucher`,params,{headers: {'application': 'application/json'}})
    },
    //办证逾期操作【保存 overdue_save, 审核申请 overdue_check_apply, 退回费用确认 back_voucher_waited, 审核通过 overdue_check_success, 审核驳回 overdue_check_reject】
    postTaskOperaOverdue(params){
      return instance.post(`/pricing-trade/certificate/task/opera/overdue`,params,{headers: {'application': 'application/json'}})
    },
    // 办证公司结算费用 新增
    postCertificateFeeClearAdd(params){
      return instance.post(`/pricing-trade/certificate/fee/clear/add`,params,{headers: {'application': 'application/json'}})
    },
    //办证公司结算费用 删除
    deleteCertificateFeeClear(id,params){
      return instance.delete(`/pricing-trade/certificate/fee/clear/delete/${id}`,params)
    },
    

    //查询办证项目类型状态
    getCertKind(params){
      return instance.get(`/pricing-trade/certificate/getCertKind`,{params})
    },
    //沪牌查验操作【确认查验 plate_check_confirm】
    postTaskOperaPlate(params){
      return instance.post(`/pricing-trade/certificate/task/opera/plate`,params,{headers: {'application': 'application/json'}})
    },
    //沪牌查验单改为已查验
    postTaskOperaPlateFinish(params){
      return instance.post(`/pricing-trade/certificate/task/opera/plate/finish`,params,{headers: {'application': 'application/json'}})
    },


    
    

    //办证费用标准 列表查询
    postFeeStandardList(params){
      return instance.post(`/pricing-trade/certificate/fee/standard/list`,params,{headers: {'application': 'application/json'}})
    },
    //办证费用标准 新增
    postFeeStandardAdd(params){
      return instance.post(`/pricing-trade/certificate/fee/standard/add`,params,{headers: {'application': 'application/json'}})
    },
    //办证费用标准 更新
    updateFeeStandard(id,params){
      return instance.put(`/pricing-trade/certificate/fee/standard/update/${id}`,params)
    },
    //办证费用标准 删除
    deleteFeeStandard(id,params){
      return instance.delete(`/pricing-trade/certificate/fee/standard/delete/${id}`,params)
    },
    //车辆材料手续 列表查询【此接口只查材料手续，办证模块的列表全是办证任务，调/task/page接口】
    postProcedureMaterialList(params){
      return instance.post(`/pricing-trade/certificate/procedure/material/list`,params,{headers: {'application': 'application/json'}})
    },
     //查询补办项目状态
    getReplaceItem(){
      return instance.get(`/pricing-trade/certificate/getReplaceItem`)
    },
    //新增 车辆材料手续补办
    postProcedureMaterialAdd(params){
      return instance.post(`/pricing-trade/certificate/procedure/material/add`,params,{headers: {'application': 'application/json'}})
    },
    //根据 车牌号 查询车辆简单详情
    getSimpledetailCar(carNo){
      return instance.get(`/pricing-trade/car/simple_detail/${carNo}`)
    },
    //更新 车辆手续补办
    postProcedureUpdate(id,params){
      return instance.put(`/pricing-trade/certificate/procedure/material/update/${id}`,params)
    },
    //车辆材料手续 删除
    postProcedureDelete(id,params){
      return instance.delete(`/pricing-trade/certificate/procedure/material/delete/${id}`,params)
    },
    //手续补办操作【确认补办/提交 procedure_confirm, 补办完成 procedure_finish, 补办退回 procedure_reject】
    postTaskOperaProcedure(params){
      return instance.post(`/pricing-trade/certificate/task/opera/procedure`,params,{headers: {'application': 'application/json'}})
    },
    // 手续补办操作【办证公司提交 procedure_com_submit】
    postTaskOperaCompanyProcedure(params){
      return instance.post(`/pricing-trade/certificate/task/opera/company/procedure`,params,{headers: {'application': 'application/json'}})
    },
    //新增 违章处理
    postBreakruleAdd(params){
      return instance.post('/pricing-trade/certificate/break_rule/add',params,{headers: {'application': 'application/json'}})
    },
    //新增 违章跟进记录
    postBreakRuleTraceAdd(params){
      return instance.post('/pricing-trade/certificate/break_rule/trace/add',params,{headers: {'application': 'application/json'}})
    },
    
    //更新 违章处理
    postBreakruleUpdate(id,params){
      return instance.post(`/pricing-trade/certificate/break_rule/update/${id}`,params,{headers: {'application': 'application/json'}})
    },
    //完成 违章处理
    postBreakruleFinish(id,params){
      return instance.post(`/pricing-trade/certificate/break_rule/finish/${id}`,params,{headers: {'application': 'application/json'}})
    },
    //完成/退回 车辆材料手续补办
    postMaterialOpera(id,operaType,params){
      return instance.post(`/pricing-trade/certificate/procedure/material/opera/${id}?operaType=${operaType}`,params,{headers: {'application': 'application/json'}})
    },
    //违章处理操作【费用确认 break_confirm, 继续跟进 break_trace_continue，处理完成 break_check_success】
    postTaskOperaRule(params){
      return instance.post(`/pricing-trade/certificate/task/opera/rule`,params,{headers: {'application': 'application/json'}})
    },
    //违章处理操作【费用确认 break_confirm】
    postTaskOperaRuleConfirm(params){
      return instance.post(`/pricing-trade/certificate/task/opera/rule/confirm`,params,{headers: {'application': 'application/json'}})
    },
    //办证逾期/异常 删除
    deleteOverdueExcept(id,params){
      return instance.delete(`/pricing-trade/certificate/overdue/delete/${id}`,params)
    },
    //办证逾期/异常 新增
    postOverdueExceptAdd(params){
      return instance.post(`/pricing-trade/certificate/overdue/add`,params,{headers: {'application': 'application/json'}})
    },
    //合同备注费用 更新
    postContractFeeUpdate(id,params){
      return instance.put(`/pricing-trade/certificate/contract/fee/update/${id}`,params,{headers: {'application': 'application/json'}})
    },
    
    //办证逾期/异常 更新[不能更新状态]
    postOverdueExceptUpdate(id,params){
      return instance.put(`/pricing-trade/certificate/overdue/except/update/${id}`,params)
    },
    //商户自理费用操作【保存 self_save, 审核通过 self_check_success】
    postOperaSelf(params){
      return instance.post(`/pricing-trade/certificate/task/opera/self`,params,{headers: {'application': 'application/json'}})
    },
    //放弃付意向金/无需付款
    postCustomerGiveUpPay(params){
      return instance.post(`/pricing-trade/contract/customer/customerGiveUpPay?${qs.stringify(params)}`,params,{headers: {'application': 'application/json'}})
    },
    //条件查询[不分页]意向金列表
    postFinanceIntentionMoneyList(params){
      return instance.post(`/pricing-finance/intentionMoney/list`,params,{headers: {'application': 'application/json'}})
    },
    //中间退费条件查询
    postContractCustomer(params){
      return instance.post(`/pricing-trade/contract/customer/middle/query`,params,{headers: {'application': 'application/json'}})
    },
    //根据客户合同ID查询详情
    getDetailsByCcId(ccId,params){
      return instance.get(`/pricing-trade/contract/customer/middleFee/getDetailsByCcId/${ccId}`,{params})
    },
    //办证进度信息列表
    postProgressPage(params){
      return instance.post(`/pricing-trade/certificate/progress/page`,params,{headers: {'application': 'application/json'}})
    },
    //办证进度信息详情
    postProgressDetail(carId,params){
      return instance.post(`/pricing-trade/certificate/progress/detail/${carId}`,params,{headers: {'application': 'application/json'}})
    },
    



    
    /**办证专员**结束**/

    /**客户合同**开始**/
    //根据条件查询 客户合同 列表
    getCustomerList(params){
      return instance.post('/pricing-trade/contract/customer/list',params,{headers: {'application': 'application/json'}})
    },
    //查询维保记录（合同的查询）
    getMaintenanceReportQuery(params){
      return instance.get(`/pricing-check/maintenance/report/query?${qs.stringify(params)}`)
    },
    //查询维保记录（权限的专用查询）
    getMaintenanceReportPage(params){
      return instance.post('/pricing-check/maintenance/report/page',params,{headers: {'application': 'application/json'}})
    },

    //领取/制作/签订 合同
    getCustomerCheck(contractId,checkType,params){
      return instance.post(`/pricing-trade/contract/customer/check/${contractId}/${checkType}`,params,{headers: {'application': 'application/json'}})
    },
    //签订失败 合同
    getCustomerSignFail(contractId,params){
      return instance.post(`/pricing-trade/contract/customer/sign/fail/${contractId}`,params,{headers: {'application': 'application/json'}})
    },
    //根据客户ID查询客户银行账号历史记录
    getAccountHistory(params){
      return instance.post(`/pricing-trade/contract/customer/account/history?${qs.stringify(params)}`,params,{headers: {'application': 'application/json'}})
    },
    




    //成交归属下拉 列表
    getlistTradeOwned(roleName,params){
      return instance.get(`/pricing-trade/contract/customer/listTradeOwned/${roleName}`,{params})
    },
    //根据 合同id 查询合同详情以及关系
    getCustomerDetail(contractId,params){
      return instance.get(`/pricing-trade/contract/customer/detail/${contractId}`,{params})
    },
    //获取车管所信息 仅车辆审核阶段 车管信息为空时可获取
    getCarManagerInfoFromDMVFrTrade(params){
      return instance.post(`/pricing-check/checkReport/getCarManagerInfoFromDMVFrTrade?${qs.stringify(params)}`,params,{headers: {'application': 'application/json'}})
    },
    //车牌颜色 列表查询
    queryPlateColor(params){
      return instance.get(`/pricing-trade/car/queryPlateColor`,{params})
    },
    //车辆持有人性质 列表查询
    queryCarOwnerNature(carId,params){
      return instance.get(`/pricing-trade/car/queryCarOwnerNature/${carId}`,{params})
    },
    //自动计算办证日期约定 客户合同中的约定完成办证手续日期
    getcertDate(contractId,params){
      return instance.post(`/pricing-trade/contract/customer/calculate/certDate/${contractId}`,params,{headers: {'application': 'application/json'}})
    },
    //自动计算委托出售价格 客户合同中的押金与费用以及应支付的车款
    getCalculatePlay(contractId,params){
      return instance.post(`/pricing-trade/contract/customer/calculate/pay/${contractId}`,params,{headers: {'application': 'application/json'}})
    },
    //委托出售价格,全押车款, 客户合同
    getfullPlay(contractId,params){
      return instance.post(`/pricing-trade/contract/customer/full/pay/${contractId}`,params,{headers: {'application': 'application/json'}})
    },
    //更新 客户合同（保存操作）
    updateContract(contractId,params){
      return instance.post(`/pricing-trade/contract/customer/update/${contractId}`,params,{headers: {'application': 'application/json'}})
    },
    //删除/作废 客户合同要求
    deleteRequire(params){
      return instance.put(`/pricing-trade/contract/customer/require/delete`,params,{headers: {'application': 'application/json'}})
    },
    //根据 合同id 设置合同为已打印状态
    postContractPrint(contractId,params){
      return instance.post(`/pricing-trade/contract/customer/print/${contractId}`,params,{headers: {'application': 'application/json'}})
    },
    //删除/作废 客户合同
    deleteCustomer(contractId,params){
      return instance.put(`/pricing-trade/contract/customer/delete/${contractId}`,params,{headers: {'application': 'application/json'}})
    },
    //客户银行账号 更新
    updateAccount(params){
      return instance.post(`/pricing-trade/car/customer/account/update`,params,{headers: {'application': 'application/json'}})
    },
    //根据 车ID和商户ID 查询商户合同
    getContractMerchant(carId,merchantId,params){
      return instance.get(`/pricing-trade/contract/merchant/get/${carId}/${merchantId}`,{params})
    },
    //根据 合同id 申请中间退费
    postMiddleFeeApply(params){
      return instance.post(`/pricing-trade/contract/customer/middleFee/apply`,params,{headers: {'application': 'application/json'}})
    },
    //根据 客户合同id 申请中间退费
    postCustomerMiddleFeeApply(contractId,params){
      return instance.post(`/pricing-trade/contract/customer/middleFee/apply/${contractId}`,params,{headers: {'application': 'application/json'}})
    },
    //根据feeId查询详情
    getDetailsByFeeIdCustomer(feeId,params){
      return instance.get(`/pricing-trade/contract/customer/getDetailsByFeeId/${feeId}`,{params})
    },
    //车辆押金退费材料 列表查询
    postCarMmaterialsList(carId,feeBackApplyStatus,params){
      return instance.post(`/pricing-trade/car/materials/list/${carId}/${feeBackApplyStatus}`,params,{headers: {'application': 'application/json'}})
    },


    

    
    /**客户合同**结束**/

    /**库管员开始**/
    //物流 列表查询（收货管理）
    getLogisticsList(params){
      return instance.post(`/pricing-trade/logistics/list`,params,{headers: {'application': 'application/json'}})
    },
    //根据 物流ID 查询详情以及关系
    getLogisticsDetail(logistId,params){
      return instance.post(`/pricing-trade/logistics/detail/${logistId}`,params,{headers: {'application': 'application/json'}})
    },
    //根据发货单ID废弃发货单
    getLogistDiscard(logistId,params){
      return instance.get(`/pricing-trade/logistics/logistDiscard/${logistId}`,{params})
    },
    //异常处理
    postExceptionHandling(logistId,params){
      return instance.post(`/pricing-trade/logistics/exceptionHandling/${logistId}`,params,{headers: {'application': 'application/json'}})
    },
    //出库，出库并发货（出库对象为交付中心，出库类型调拨出库），入库，一键出库 等处理；
    postStockHandling(params){
      return instance.post(`/pricing-trade/stock/stockHandling`,params,{headers: {'application': 'application/json'}})
    },
    //仓库列表查询，参数全空查全部，同时联动查询仓库，货区，货位
    getStockObjList(params){
      return instance.get(`/pricing-trade/stock/stockObj/list`,{params})
    },
    //库存 列表查询
    getStockList(params){
      return instance.post(`/pricing-trade/stock/list`,params,{headers: {'application': 'application/json'}})
    },
    //车辆库存 列表查询
    getStockCarList(params){
      return instance.post(`/pricing-trade/stock/car/page`,params,{headers: {'application': 'application/json'}})
    },
    //根据 库存ID 查询详情以及关系
    getStockDetail(stockId,params){
      return instance.post(`/pricing-trade/stock/detail/${stockId}`,params,{headers: {'application': 'application/json'}})
    },
    //车辆/材料出入库流水 查询
    getStockFlow(stockId,params){
      return instance.get(`/pricing-trade/stock/flow/${stockId}`,{params})
    },
    //车辆材料/手续 列表查询
    getMaterialsList(params){
      return instance.post(`/pricing-trade/car/materials/list`,params,{headers: {'application': 'application/json'}})
    },
    //根据车牌号查询在库材料
    getStockMaterialsRela(carNo,params){
      return instance.get(`/pricing-trade/stock/stockMaterialsRela/list/${carNo}`,{params})
    },
    
    //发货单 更新（填写发货单是更新）
    updateLogistics(logistId,params){
      return instance.post(`/pricing-trade/logistics/update/${logistId}`,params,{headers: {'application': 'application/json'}})
    },
    //预警处理
    postAlarmHandling(logistId,params){
      return instance.post(`/pricing-trade/logistics/alarmHandling/${logistId}`,params,{headers: {'application': 'application/json'}})
    },
    //收货(选择接收，一键收货)，货物异常 等处理
    postLogistHandling(params){
      return instance.post(`/pricing-trade/logistics/logistHandling`,params,{headers: {'application': 'application/json'}})
    },
    //车辆钥匙 列表查询
    getCarKeyList(params){
      return instance.post(`/pricing-trade/stock/carKey/list`,params,{headers: {'application': 'application/json'}})
    },
    //根据 车辆钥匙ID 查询详情以及关系
    getCarKeyDetail(carKeyId,params){
      return instance.post(`/pricing-trade/stock/carKey/detail/${carKeyId}`,params,{headers: {'application': 'application/json'}})
    },
    //车辆钥匙 借用key_borrow，归还key_give 等处理；
    postCarKeyHandling(params){
      return instance.post(`/pricing-trade/stock/carKey/handling`,params,{headers: {'application': 'application/json'}})
    },
    //车辆钥匙-处置预警 新增
    postAlarmAdd(params){
      return instance.post(`/pricing-trade/stock/carKey/alarm/add`,params,{headers: {'application': 'application/json'}})
    },
    //根据 车牌号 查询车辆以及相关的详情
    getCarDetail(carNo,params){
      return instance.get(`/pricing-trade/car/detail/${carNo}`,{params})
    },

    /**库管员结束**/

    /**财务模块开始**/
    //工作台统计(财务专用)
    getFinanceCountWorkBench(params){
      return instance.get(`/pricing-finance/workbench/countWorkBench`,{params})
    },
    //查询银行支行列表，不支持全部查询【不分页】
    postbankList(params){
      return instance.post(`/pricing-finance/bank/sub/list`,params,{headers: {'application': 'application/json'}})
    },
    //查询银行总行列表【不分页】
    postbankHeadList(params){
      return instance.post(`/pricing-finance/bank/head/list`,params,{headers: {'application': 'application/json'}})
    },
    //根据门店Id获取ok卡
    getOKCarlist(shopId,params){
      return instance.get(`/pricing-finance/ok/card/list/${shopId}`,{params})
    },
    //查询门店库存信息
    getOKCardStore(params){
      return instance.get(`/pricing-finance/ok/card/store`,{params})
    },
    //门店库存设置
    postOKcardStore(params){
      return instance.post(`/pricing-finance/ok/card/store/set`,params,{headers: {'application': 'application/json'}})
    },
    //ok卡 查询列表【分页】
    postOKcardList(params){
      return instance.post(`/pricing-finance/ok/card/query`,params,{headers: {'application': 'application/json'}})
    },
    //ok卡 查询列表【不分页】
    postOKcardListArr(params){
      return instance.post(`/pricing-finance/ok/card/list`,params,{headers: {'application': 'application/json'}})
    },
    //ok卡领取
    getOKcard(shopId,okCardIds,params){
      return instance.post(`/pricing-finance/ok/card/get/${shopId}?okCardIds=${okCardIds}`,params,{headers: {'application': 'application/json'}})
    },
    //ok卡新增
    postOKcardAdd(params){
      return instance.post(`/pricing-finance/ok/card/add`,params,{headers: {'application': 'application/json'}})
    },
    //ok卡修改
    postOKcardUpdate(okCardId,params){
      return instance.post(`/pricing-finance/ok/card/update/${okCardId}`,params,{headers: {'application': 'application/json'}})
    },
    //ok卡 删除
    deleteOkCard(params){
      return instance.delete(`/pricing-finance/ok/card/delete`,{params})
    },
    //ok卡 导出
    postOKcardExport(params){
      return instance({
        url: '/pricing-finance/ok/card/export',
        method: 'post',
        data: params,
        responseType: 'blob',
        headers: {'application': 'application/json'}
      })
    },
    //ok卡导入
    postOKcardImport(params){
      return instance.post(`/pricing-finance/ok/card/import`,params,{headers: {'application': 'multipart/form-data'}})
    },
    //客户合同查询
    postContractQuery(params){
      return instance.post(`/pricing-finance/contract/query`,params,{headers: {'application': 'application/json'}})
    },
    //客户合同归档
    postContractArchive(customerContractIds,params){
      return instance.post(`/pricing-finance/contract/archive?customerContractIds=${customerContractIds}`,params,{headers: {'application': 'application/json'}})
    },
    //导出客户合同归档
    postContractExport(params){
      return instance({
        url: '/pricing-finance/contract/export',
        method: 'post',
        data: params,
        responseType: 'blob',
        headers: {'application': 'application/json'}
      })
    },
    //中间退费条件查询
    postMiddleQuery(params){
      return instance.post(`/pricing-finance/middle/query`,params,{headers: {'application': 'application/json'}})
    },
    //根据feeId查询详情
    getDetailsByFeeId(feeId,params){
      return instance.get(`/pricing-finance/middle/getDetailsByFeeId/${feeId}`,{params})
    },
    //财务单个退费（中间退费）
    postMiddleFinancePay(params){
      return instance.post(`/pricing-finance/middle/financePay`,params,{headers: {'application': 'application/json'}})
    },
    //财务批量退费（中间退费）
    postMiddleFinanceBatchPay(params){
      return instance.post(`/pricing-finance/middle/financeBatchPay`,params,{headers: {'application': 'application/json'}})
    },
    //财务确认付款失败（中间退费）
    postMiddleFinanceConfirmPayFail(orderNo,params){
      return instance.post(`/pricing-finance/middle/financeConfirmPayFail?orderNo=${orderNo}`,params,{headers: {'application': 'application/json'}})
    },
    //财务确认付款成功（中间退费）
    postMiddleFinanceConfirmPaySuccess(orderNo,params){
      return instance.post(`/pricing-finance/middle/financeConfirmPaySuccess?orderNo=${orderNo}`,params,{headers: {'application': 'application/json'}})
    },
    //中间退费批量条件查询
    postMiddleBatchQuery(params){
      return instance.post(`/pricing-finance/middle/batchQuery`,params,{headers: {'application': 'application/json'}})
    },
    //根据批次编号查询详情
    getBatchDetailsByOrderNo(batchPayOrderNo,params){
      return instance.get(`/pricing-finance/middle/getBatchDetailsByOrderNo/${batchPayOrderNo}`,{params})
    },
    //财务收退车款
    postFinanceGathering(customerCarTradePayIds,params){
      return instance.post(`/pricing-finance/customer/trade/financeGathering?customerCarTradePayIds=${customerCarTradePayIds}`,params,{headers: {'application': 'application/json'}})
    },
    //客户成交/退车辆付款条件查询
    postCustomerTradeQuery(params){
      return instance.post(`/pricing-finance/customer/trade/query`,params,{headers: {'application': 'application/json'}})
    },
    //根据customerCarTradePayId查询详情
    getDetailByCustomerCarTradePayId(customerCarTradePayId,params){
      return instance.get(`/pricing-finance/customer/trade/getDetailByCustomerCarTradePayId/${customerCarTradePayId}`,{params})
    },
    //财务付款
    postFinancePay(params){
      return instance.post(`/pricing-finance/customer/trade/financePay`,params,{headers: {'application': 'application/json'}})
    },
    //财务确认付款成功(客户)
    postFinanceConfirmPaySuccess(orderNo,params){
      return instance.post(`/pricing-finance/customer/trade/financeConfirmPaySuccess?orderNo=${orderNo}`,params,{headers: {'application': 'application/json'}})
    },
    //财务确认付款失败(客户)
    postFinanceConfirmPayFail(orderNo,params){
      return instance.post(`/pricing-finance/customer/trade/financeConfirmPayFail?orderNo=${orderNo}`,params,{headers: {'application': 'application/json'}})
    },
    //客户押金退款 条件查询
    postCustomerDeposit(params){
      return instance.post(`/pricing-finance/customer/deposit/query`,params,{headers: {'application': 'application/json'}})
    },
    //客户押金退款 批量条件查询
    postCustomerDepositBatchQuery(params){
      return instance.post(`/pricing-finance/customer/deposit/batchQuery`,params,{headers: {'application': 'application/json'}})
    },
    //根据 depositPayId 查询详情
    getDepositDetailsByDepositPayId(depositPayId,params){
      return instance.get(`/pricing-finance/customer/deposit/getDetailsByDepositPayId/${depositPayId}`,{params})
    },
    //根据批次编号查询详情
    getDepositBatchDetailsByOrderNo(batchPayOrderNo,params){
      return instance.get(`/pricing-finance/customer/deposit/getBatchDetailsByOrderNo/${batchPayOrderNo}`,{params})
    },
    //财务单个付款（客户押金退款）
    postDepositFinancePay(params){
      return instance.post(`/pricing-finance/customer/deposit/financePay`,params,{headers: {'application': 'application/json'}})
    },
    //财务批量付款（客户押金退款）
    postDepositFinanceBatchPay(params){
      return instance.post(`/pricing-finance/customer/deposit/financeBatchPay`,params,{headers: {'application': 'application/json'}})
    },
    //财务确认付款成功（客户押金退款）
    postDepositFinanceConfirmPaySuccess(orderNo,params){
      return instance.post(`/pricing-finance/customer/deposit/financeConfirmPaySuccess?orderNo=${orderNo}`,params,{headers: {'application': 'application/json'}})
    },
    //财务确认付款失败（客户押金退款）
    postDepositFinanceConfirmPayFail(orderNo,params){
      return instance.post(`/pricing-finance/customer/deposit/financeConfirmPayFail?orderNo=${orderNo}`,params,{headers: {'application': 'application/json'}})
    },
    //分页查询商户保证金收款
    postFinanceMerchantGathering(params){
      return instance.post(`/pricing-finance/finance/merchant/margin/gathering/query`,params,{headers: {'application': 'application/json'}})
    },
    //根据商户保证金财务付款单ID查询详情
    getDetailsByGatheringId(merchantMarginGatheringId,params){
      return instance.get(`/pricing-finance/finance/merchant/margin/gathering/getDetailsByGatheringId/${merchantMarginGatheringId}`,{params})
    },
    //财务确认付款成功（保证金）
    postMerchantFinanceConfirmPaySuccess(params){
      return instance.post(`/pricing-finance/finance/merchant/margin/gathering/financeConfirmPaySuccess?${qs.stringify(params)}`,params,{headers: {'application': 'application/json'}})
    },
    //财务确认付款失败（保证金）
    postMerchantFinanceConfirmPayFail(params){
      return instance.post(`/pricing-finance/finance/merchant/margin/gathering/financeConfirmPayFail?${qs.stringify(params)}`,params,{headers: {'application': 'application/json'}})
    },
    //财务保证金管理 导出
    postFinanceMerchantExport(params){
      return instance({
        url: '/pricing-finance/finance/merchant/margin/gathering/export',
        method: 'post',
        data: params,
        responseType: 'blob',
        headers: {'application': 'application/json'}
      })
    },
    //商户成交/退车辆付款条件查询
    postMerchantTradeQuery(params){
      return instance.post(`/pricing-finance/merchant/trade/query`,params,{headers: {'application': 'application/json'}})
    },
    //根据merchantCarTradePayId查询详情
    getDetailByMerchantCarTradePayId(merchantCarTradePayId,params){
      return instance.get(`/pricing-finance/merchant/trade/getDetailByMerchantCarTradePayId/${merchantCarTradePayId}`,{params})
    },
    //根据商户ID查询商户支付方式列表
    getMerchantPayTypeListByMerchantId(merchantId,params){
      return instance.post(`/pricing-finance/merchant/trade/getMerchantPayTypeListByMerchantId/${merchantId}`,params,{headers: {'application': 'application/json'}})
    },
    //财务确认付款成功（商户成交/退车款）
    postMerchantTradeFinanceConfirmPaySuccess(params){
      return instance.post(`/pricing-finance/merchant/trade/financeConfirmPaySuccess`,params,{headers: {'application': 'application/json'}})
    },
    //财务收退车款
    postMerchantTradeFinanceGathering(params){
      return instance.post(`/pricing-finance/merchant/trade/financeGathering`,params,{headers: {'application': 'application/json'}})
    },
    //商户押金退款 条件查询
    postMerchantDepositQuery(params){
      return instance.post(`/pricing-finance/merchant/deposit/query`,params,{headers: {'application': 'application/json'}})
    },
    //商户押金退款 批量条件查询
    postMerchantBatchQuery(params){
      return instance.post(`/pricing-finance/merchant/deposit/batchQuery`,params,{headers: {'application': 'application/json'}})
    },
    //根据 depositPayId 查询详情（商户押金）
    getMerchantDepositDetailsByDepositPayId(depositPayId,params){
      return instance.get(`/pricing-finance/merchant/deposit/getDetailsByDepositPayId/${depositPayId}`,{params})
    },
    //根据批次编号查询详情
    getBatchDetailsByOrderNoMerchant(batchPayOrderNo,params){
      return instance.get(`/pricing-finance/merchant/deposit/getBatchDetailsByOrderNo/${batchPayOrderNo}`,{params})
    },
    //财务单个付款（商户押金）
    postMerchantDepositFinancePay(params){
      return instance.post(`/pricing-finance/merchant/deposit/financePay`,params,{headers: {'application': 'application/json'}})
    },
    //财务批量付款
    postMerchantDepositFinanceBatchPay(params){
      return instance.post(`/pricing-finance/merchant/deposit/financeBatchPay`,params,{headers: {'application': 'application/json'}})
    },
    //财务确认批量付款失败
    postFinanceBatchConfirmPayFail(params){
      return instance.post(`/pricing-finance/merchant/deposit/financeBatchConfirmPayFail?${qs.stringify(params)}`,params,{headers: {'application': 'application/json'}})
    },
    //财务确认批量付款成功
    postFinanceBatchConfirmPaySuccess(params){
      return instance.post(`/pricing-finance/merchant/deposit/financeBatchConfirmPaySuccess?${qs.stringify(params)}`,params,{headers: {'application': 'application/json'}})
    },
    //财务批量付款失败后再次付款
    postFinanceBatchPayByMerOrd(params){
      return instance.post(`/pricing-finance/merchant/deposit/financeBatchPayByMerOrd`,params,{headers: {'application': 'application/json'}})
    },
    //财务确认付款成功（商户押金）
    postMerchantDepositFinanceConfirmPaySuccess(orderNo,params){
      return instance.post(`/pricing-finance/merchant/deposit/financeConfirmPaySuccess?orderNo=${orderNo}`,params,{headers: {'application': 'application/json'}})
    },
    //财务确认付款失败（商户押金）
    postMerchantDepositFinanceConfirmPayFail(orderNo,params){
      return instance.post(`/pricing-finance/merchant/deposit/financeConfirmPayFail?orderNo=${orderNo}`,params,{headers: {'application': 'application/json'}})
    },
    //分页查询商户退款-财务付款（商户预付款/商户保证金退款）
    postMerchantRefundPayQuery(params){
      return instance.post(`/pricing-finance/finance/merchant/refund/pay/query`,params,{headers: {'application': 'application/json'}})
    },
    //根据商户退款ID查询财务付款详情（商户预付款/商户保证金退款）
    getRefundDetailsByRefundId(merchantRefundPayId,params){
      return instance.get(`/pricing-finance/finance/merchant/refund/pay/getRefundDetailsByRefundId/${merchantRefundPayId}`,{params})
    },
    //财务单个付款（商户预付款/商户保证金退款）
    postMerchantRefundPayFinancePay(params){
      return instance.post(`/pricing-finance/finance/merchant/refund/pay/financePay`,params,{headers: {'application': 'application/json'}})
    },
    //财务确认付款成功（商户预付款/商户保证金退款）
    postMerchantRefundPayFinanceConfirmPaySuccess(orderNo,params){
      return instance.post(`/pricing-finance/finance/merchant/refund/pay/financeConfirmPaySuccess?orderNo=${orderNo}`,params,{headers: {'application': 'application/json'}})
    },
    //财务确认付款失败（商户预付款/商户保证金退款）
    postMerchantRefundPayFinanceConfirmPayFail(orderNo,params){
      return instance.post(`/pricing-finance/finance/merchant/refund/pay/financeConfirmPayFail?orderNo=${orderNo}`,params,{headers: {'application': 'application/json'}})
    },
    //财务退回付款
    postPayFinanceReject(params){
      return instance.post(`/pricing-finance/finance/merchant/refund/pay/financeReject?${qs.stringify(params)}`,params,{headers: {'application': 'application/json'}})
    },
    

    //付款条件查询（渠道钱包提现）
    postChannelWalletQuery(params){
      return instance.post(`/pricing-finance/channel/wallet/query`,params,{headers: {'application': 'application/json'}})
    },
    //付款批量条件查询（渠道钱包提现）
    postChannelWalletBatchQuery(params){
      return instance.post(`/pricing-finance/channel/wallet/batchQuery`,params,{headers: {'application': 'application/json'}})
    },
    //根据walletPayId查询详情（渠道钱包提现）
    getChannelDetailByWalletPayId(walletPayId,params){
      return instance.get(`/pricing-finance/channel/wallet/getDetailByWalletPayId/${walletPayId}`,{params})
    },
    //根据批次编号查询详情（渠道钱包提现）
    getChannelBatchDetailsByOrderNo(batchPayOrderNo,params){
      return instance.get(`/pricing-finance/channel/wallet/getBatchDetailsByOrderNo/${batchPayOrderNo}`,{params})
    },
    //财务单个付款（渠道钱包提现）
    postChannelWalletFinancePay(params){
      return instance.post(`/pricing-finance/channel/wallet/financePay`,params,{headers: {'application': 'application/json'}})
    },
    //财务批量付款（渠道钱包提现）
    postChannelWalletFinanceBatchPay(params){
      return instance.post(`/pricing-finance/channel/wallet/financeBatchPay`,params,{headers: {'application': 'application/json'}})
    },
    //财务确认付款成功（渠道钱包提现）
    postChannelWalletFinanceConfirmPaySuccess(orderNo,params){
      return instance.post(`/pricing-finance/channel/wallet/financeConfirmPaySuccess?orderNo=${orderNo}`,params,{headers: {'application': 'application/json'}})
    },
    //财务确认付款失败（渠道钱包提现）
    postChannelWalletFinanceConfirmPayFail(orderNo,params){
      return instance.post(`/pricing-finance/channel/wallet/financeConfirmPayFail?orderNo=${orderNo}`,params,{headers: {'application': 'application/json'}})
    },
    //查询银行账户余额
    getAvailableAmount(params){
      return instance.get(`/pricing-finance/bank/account/available/amount`,{params})
    },
    

    //违章付款条件查询
    postBreakRuleQuery(params){
      return instance.post(`/pricing-finance/breakRule/query`,params,{headers: {'application': 'application/json'}})
    },
    //违章付款批量条件查询
    postBreakRuleBatchQueryy(params){
      return instance.post(`/pricing-finance/breakRule/batchQuery`,params,{headers: {'application': 'application/json'}})
    },
    //根据feeId查询详情（违章付款）
    getBreakRuleDetailByBreakRulePayId(breakRulePayId,params){
      return instance.get(`/pricing-finance/breakRule/getDetailByBreakRulePayId/${breakRulePayId}`,{params})
    },
    //根据批次编号查询详情（违章付款）
    getBreakRuleBatchDetailsByOrderNo(batchPayOrderNo,params){
      return instance.get(`/pricing-finance/breakRule/getBatchDetailsByOrderNo/${batchPayOrderNo}`,{params})
    },
    //财务单个付款（违章付款）
    postBreakRuleFinancePay(params){
      return instance.post(`/pricing-finance/breakRule/financePay`,params,{headers: {'application': 'application/json'}})
    },
    //财务批量付款（违章付款）
    postBreakRuleFinanceBatchPay(params){
      return instance.post(`/pricing-finance/breakRule/financeBatchPay`,params,{headers: {'application': 'application/json'}})
    },
    //财务确认付款成功（违章付款）
    postBreakRuleFinanceConfirmPaySuccess(orderNo,params){
      return instance.post(`/pricing-finance/breakRule/financeConfirmPaySuccess?orderNo=${orderNo}`,params,{headers: {'application': 'application/json'}})
    },
    //财务确认付款失败（违章付款）
    postBreakRuleFinanceConfirmPayFail(orderNo,params){
      return instance.post(`/pricing-finance/breakRule/financeConfirmPayFail?orderNo=${orderNo}`,params,{headers: {'application': 'application/json'}})
    },
    //中间退费明细查询【分页】(报表)
    postReportMiddleFeePay(params){
      return instance.post(`/pricing-finance/report/middle/fee/pay/page`,params,{headers: {'application': 'application/json'}})
    },
    //中间退费明细查询 导出(报表)
    exportMiddleFeePay(params){
      return instance({
        url: '/pricing-finance/report/middle/fee/pay/export',
        method: 'post',
        data: params,
        responseType: 'blob',
        headers: {'application': 'application/json'}
      })
    },
    //中间退费财务报表明细 导出(报表)
    exportMiddleFeePayFinance(params){
      return instance({
        url: '/pricing-finance/report/middle/fee/pay/finance/export',
        method: 'post',
        data: params,
        responseType: 'blob',
        headers: {'application': 'application/json'}
      })
    },
    //商户收款明细查询【分页】(报表)
    postReportMerchantCarTradePay(params){
      return instance.post(`/pricing-finance/report/merchant/car/trade/pay/page`,params,{headers: {'application': 'application/json'}})
    },
    //商户收款明细 导出(报表)
    exportReportMerchantCarTradePay(params){
      return instance({
        url: '/pricing-finance/report/merchant/car/trade/pay/export',
        method: 'post',
        data: params,
        responseType: 'blob',
        headers: {'application': 'application/json'}
      })
    },
     //商户收款明细 财务报表导出(报表)
     exportReportMerchantCarTradePayFinance(params){
      return instance({
        url: '/pricing-finance/report/merchant/car/trade/pay/finance/export',
        method: 'post',
        data: params,
        responseType: 'blob',
        headers: {'application': 'application/json'}
      })
    },
    //客户成交车款支付明细【分页】(报表)
    postReportCustomerCarTradePay(params){
      return instance.post(`/pricing-finance/report/customer/car/trade/pay`,params,{headers: {'application': 'application/json'}})
    },
    //客户成交车款支付明细 导出(报表)
    exportReportCustomerCarTradePay(params){
      return instance({
        url: '/pricing-finance/report/customer/car/trade/pay/export',
        method: 'post',
        data: params,
        responseType: 'blob',
        headers: {'application': 'application/json'}
      })
    },
    //客户成交车款支付明细财务报表 导出(报表)
    exportReportCustomerCarTradePayFinance(params){
      return instance({
        url: '/pricing-finance/report/customer/car/trade/pay/finance/export',
        method: 'post',
        data: params,
        responseType: 'blob',
        headers: {'application': 'application/json'}
      })
    },
    //客户成交车款明细【分页】(报表)
    postReportCustomerCarTrade(params){
      return instance.post(`/pricing-finance/report/customer/car/trade`,params,{headers: {'application': 'application/json'}})
    },
    //客户成交车款明细 导出(报表)
    exportReportCustomerCarTrade(params){
      return instance({
        url: '/pricing-finance/report/customer/car/trade/export',
        method: 'post',
        data: params,
        responseType: 'blob',
        headers: {'application': 'application/json'}
      })
    },
    //财务付办证公司明细查询列表【分页】(报表)
    postReportCertFinancePay(params){
      return instance.post(`/pricing-finance/report/cert/finance/pay/page`,params,{headers: {'application': 'application/json'}})
    },
    //付办证公司明细 导出
    exportFinancePayExport(params){
      return instance({
        url: '/pricing-finance/report/cert/finance/pay/export',
        method: 'post',
        data: params,
        responseType: 'blob',
        headers: {'application': 'application/json'}
      })
    },
    //意向金报表明细【分页】
    postReportIntentionMoneyPay(params){
      return instance.post(`/pricing-finance/report/intention/money/pay`,params,{headers: {'application': 'application/json'}})
    },
    //意向金报表明细 导出(报表)
    exportReportIntentionMoneyPay(params){
      return instance({
        url: '/pricing-finance/report/intention/money/pay/export',
        method: 'post',
        data: params,
        responseType: 'blob',
        headers: {'application': 'application/json'}
      })
    },
    //意向金财务报表明细 导出(报表)
    exportReportIntentionMoneyPayFinance(params){
      return instance({
        url: '/pricing-finance/report/intention/money/pay/finance/export',
        method: 'post',
        data: params,
        responseType: 'blob',
        headers: {'application': 'application/json'}
      })
    },
    //条件查询[分页]，如果是意向金报表查询则需payTypes传入1和2
    postIntentionMoneyPage(params){
      return instance.post(`/pricing-finance/intentionMoney/page`,params,{headers: {'application': 'application/json'}})
    },
    //1财务确认收款成功,2财务确认收款失败
    postFinanceConfirmGathering(operaType,payId,params){
      return instance.post(`/pricing-finance/intentionMoney/financeConfirmGathering?operaType=${operaType}&payId=${payId}`,params,{headers: {'application': 'application/json'}})
    },
    //根据payId查询详情
    getDetailsByPayId(payId,params){
      return instance.get(`/pricing-finance/intentionMoney/getDetailsByPayId/${payId}`,{params})
    },
    //财务坏账处理：财务确认赔偿客户违约金,财务确认客户返还意向金坏账,财务确认客户违约赔偿坏账
    postFinanceConfirmBadDebt(payIds,params){
      return instance.post(`/pricing-finance/intentionMoney/financeConfirmBadDebt?${qs.stringify(payIds)}`,params,{headers: {'application': 'application/json'}})
    },
    //财务确认付款失败（意向金）
    postIntentionMoneyFinanceConfirmPayFail(orderNo,params){
      return instance.post(`/pricing-finance/intentionMoney/financeConfirmPayFail?orderNo=${orderNo}`,params,{headers: {'application': 'application/json'}})
    },
    //财务确认付款成功（意向金）
    postIntentionMoneyFinanceConfirmPaySuccess(orderNo,params){
      return instance.post(`/pricing-finance/intentionMoney/financeConfirmPaySuccess?orderNo=${orderNo}`,params,{headers: {'application': 'application/json'}})
    },
    //财务支付方式列表
    getBankFinancePayTypeList(params){
      return instance.get(`/pricing-finance/bank/finance/payType/list`,{params})
    },
    //商户支付方式列表
    getBankMerchantPayTypeList(params){
      return instance.get(`/pricing-finance/bank/merchant/payType/list`,{params})
    },
    //根据车辆ID查询商户支付方式列表
    getMerchantPayTypeListByCarId(carId,params){
      return instance.post(`/pricing-finance/merchant/trade/getMerchantPayTypeListByCarId/${carId}`,params,{headers: {'application': 'application/json'}})
    },
    //跟进支付ID集合查询批量预支付详情
    getListPreBatchDetailsByPayIds(breakRulePayIds,params){
      return instance.get(`/pricing-finance/breakRule/listPreBatchDetailsByPayIds/${breakRulePayIds}`,{params})
    },
    //财务单个支付
    postIntentionMoneyFinancePay(params){
      return instance.post(`/pricing-finance/intentionMoney/financePay`,params,{headers: {'application': 'application/json'}})
    },
    
    
    
    
    //三方结算列表【分页】
    postThirdClearPage(params){
      return instance.post(`/pricing-finance/report/third/clear/page`,params,{headers: {'application': 'application/json'}})
    },
    //三方结算财务报表 导出
    postThirdClearFinanceExport(params){
      return instance({
        url: `/pricing-finance/report/third/clear/finance/export`,
        method: 'post',
        data: params,
        responseType: 'blob',
        headers: {'application': 'application/json'}
      })
    },
    //三方结算明细报表 导出
    postThirdClearExport(params){
      return instance({
        url: `/pricing-finance/report/third/clear/export`,
        method: 'post',
        data: params,
        responseType: 'blob',
        headers: {'application': 'application/json'}
      })
    },
    //三方结算详情查询
    getThirdClearDetail(thirdClearId,params){
      return instance.get(`/pricing-finance/report/third/clear/detail/${thirdClearId}`,{params})
    },
    //付客户明细【分页】
    postReportCustomerPay(params){
      return instance.post(`/pricing-finance/report/customer/pay`,params,{headers: {'application': 'application/json'}})
    },
    //付客户明细 导出
    postReportCustomerPayExport(params){
      return instance({
        url: `/pricing-finance/report/customer/pay/export`,
        method: 'post',
        data: params,
        responseType: 'blob',
        headers: {'application': 'application/json'}
      })
    },
    //付商户明细【分页】
    postReportMerchantPay(params){
      return instance.post(`/pricing-finance/report/merchant/pay`,params,{headers: {'application': 'application/json'}})
    },
    //付商户明细 财务报表导出
    postReportFinanceMerchantPayExport(params){
      return instance({
        url: `/pricing-finance/report/merchant/finance/pay/export`,
        method: 'post',
        data: params,
        responseType: 'blob',
        headers: {'application': 'application/json'}
      })
    },
    //付商户明细 导出
    postReportMerchantPayExport(params){
      return instance({
        url: `/pricing-finance/report/merchant/pay/export`,
        method: 'post',
        data: params,
        responseType: 'blob',
        headers: {'application': 'application/json'}
      })
    },
    //资金调拨查询【分页】
    postBankCkxFundPage(params){
      return instance.post(`/pricing-finance/bank/ckx/fund/page`,params,{headers: {'application': 'application/json'}})
    },
    //创建资金调拨
    postBankCkxFundCreate(params){
      return instance.post(`/pricing-finance/bank/ckx/fund/create`,params,{headers: {'application': 'application/json'}})
    },
    //车开新银行账号列表【不分页】
    postBankCkxFundAccountList(params){
      return instance.post(`/pricing-finance/bank/ckx/account/list`,params,{headers: {'application': 'application/json'}})
    },
    

    /**财务模块结束**/

    /**荐车达人开始**/
    //荐车达人代理人条件查询【分页】
    postAgentPage(params){
      return instance.post(`/pricing-user/jcdr/agent/page`,params,{headers: {'application': 'application/json'}})
    },
    //签约
    getAgentSign(agentId,params){
      return instance.get(`/pricing-user/jcdr/agent/sign/${agentId}`,{params})
    },
    //解约
    getAgentUnSign(agentId,params){
      return instance.get(`/pricing-user/jcdr/agent/unSign/${agentId}`,{params})
    },
    //更新代理人
    postAgentUpdate(agentId,params){
      return instance.post(`/pricing-user/jcdr/agent/update/${agentId}`,params,{headers: {'application': 'application/json'}})
    },
    //批量修改所属专员
    postBatchAlterOwnerEmp(params){
      return instance.post(`/pricing-user/jcdr/agent/batchAlterOwnerEmp?${qs.stringify(params)}`,params,{headers: {'application': 'application/json'}})
    },
    //根据荐车达人代理人ID查询详情
    getByAgentId(agentId,params){
      return instance.get(`/pricing-user/jcdr/agent/getByAgentId/${agentId}`,{params})
    },
    //客户条件查询【分页】
    postCustomerPage(params){
      return instance.post(`/pricing-user/jcdr/customer/page`,params,{headers: {'application': 'application/json'}})
    },
    /**荐车达人结束**/


    /**宝马对接开始**/
    //条件查询[分页]（上传过户凭证，上传资金往来凭证）
    postPaymentVoucherBmwPage(params){
      return instance.post(`/pricing-finance/paymentVoucher/bmw/page`,params,{headers: {'application': 'application/json'}})
    },
    //给宝马发送付款结果
    postReceivePaymentInfo(params){
      return instance.post(`/pricing-finance/paymentVoucher/bmw/receivePaymentInfo`,params,{headers: {'application': 'application/json'}})
    },
    //根据PaymentVoucherId查询详情
    getDetailsByIdBmw(PaymentVoucherId,params){
      return instance.get(`/pricing-finance/paymentVoucher/bmw/getDetailsById/${PaymentVoucherId}`,params)
    },
    //付款凭证、过户凭证文件上传
    postBmwFileUpload(params){
      return instance.post(`/pricing-finance/paymentVoucher/bmw/upload`,params,{headers: {'application': 'multipart/form-data'}})
    },
    //根据detailId删除详情
    getDeleteDetailById(detailId,params){
      return instance.delete(`/pricing-finance/paymentVoucher/bmw/deleteDetailById/${detailId}`,params)
    },
    //保存或更新凭证详情
    postSaveOrUpdatePaymentVoucherDetails(params){
      return instance.post(`/pricing-finance/paymentVoucher/bmw/saveOrUpdatePaymentVoucherDetails`,params,{headers: {'application': 'application/json'}})
    },
    //根据PaymentVoucherId查询详情
    postUpdPaymentVoucher(params){
      return instance.post(`/pricing-finance/paymentVoucher/bmw/updPaymentVoucher`,params, {headers: {'application': 'application/json'}})
    },
    //宝马经销商条件分页查询
    postPageDealerInfo(params){
      return instance.post(`/pricing-check/bmw/pageDealerInfo`,params,{headers: {'application': 'application/json'}})
    },
    //宝马经销商绑定4s店渠道信息
    postBindDealerChannel(params){
      return instance.post(`/pricing-check/bmw/bindDealerChannel`,params,{headers: {'application': 'application/json'}})
    },
    //宝马订单任务条件分页查询
    postPageTaskInfo(params){
      return instance.post(`/pricing-check/bmw/pageTaskInfo`,params,{headers: {'application': 'application/json'}})
    },
    //宝马经销商订单任务状态
    getBmwCheckTaskStatus(params){
      return instance.get(`/pricing-check/bmw/check/taskStatus`,params)
    },
    //给宝马发送付款结果
    postReceivePaymentInfo(params){
      return instance.post(`/pricing-finance/paymentVoucher/bmw/receivePaymentInfo?${qs.stringify(params)}`,params,{headers: {'application': 'application/json'}})
    },
    //获取补充凭证信息
    getPaymentVoucherByInfo(params){
      return instance.get(`/pricing-check/bmw/getPaymentVoucherByInfo`,params)
    },
    //获取宝马订单任务拍卖报告
    getAuctionReport(checkReportId,params){
      return instance.get(`/pricing-auction/bmw/task/auctionReport/${checkReportId}`,params)
    },
    //更新宝马订单任务拍卖报告
    postAuctionReportUpdate(params){
      return instance.post(`/pricing-auction/bmw/task/auctionReport/update`,params,{headers: {'application': 'application/json'}})
    },
    /**宝马对接结束**/ 

    
    /**业务修改数据开始**/
    //修改车牌号
    postAlterCarNo(params){
      return instance.post(`/pricing-check/alter/carNo?${qs.stringify(params)}`,params,{headers: {'application': 'application/json'}})
    },
    //修改客户渠道
    postAlterCustomerChannel(params){
      return instance.post(`/pricing-check/alter/customer/channel?${qs.stringify(params)}`,params,{headers: {'application': 'application/json'}})
    },
    //成交预约恢复到指定的商户报价，成交待预约的状态
    postConfirmMerchantOrder(params){
      return instance.post(`/pricing-check/alter/dealBook/confirm/merchantOrder?${qs.stringify(params)}`,params,{headers: {'application': 'application/json'}})
    },
    //批量恢复被放弃的客户
    postGiveUpRecover(params){
      return instance.post(`/pricing-check/alter/customer/giveUp/recover`,params,{headers: {'application': 'application/json'}})
    },
    //垫资车在制作客户合同阶段，修改验车入库状态为待分配[签订成功或者失败的不能修改]
    postAlterBsiCCReValid(contractId,params){
      return instance.post(`/pricing-trade/alter/bsi/cc/reValid/${contractId}`,params,{headers: {'application': 'application/json'}})
    },
    //客户合同签订失败回退到待领取【渠道车最好由渠道部营销部的员工来回滚，否则库存和物流恢复不了】
    postSignFailRollback(contractId,params){
      return instance.post(`/pricing-trade/alter/bsi/cc/signFail/rollback/${contractId}`,params,{headers: {'application': 'application/json'}})
    },
    //修改客户成交/退车辆付款账号【只修改某一个合同相关的】
    postCustomerCarTradePayAccount(orderNo,params){
      return instance.post(`/pricing-finance/alter/customerCarTradePay/account?orderNo=${orderNo}`,params,{headers: {'application': 'application/json'}})
    },
    //修改客户押金退款账号【只修改某一个合同相关的】
    postCustomerDepositPayAccount(orderNo,params){
      return instance.post(`/pricing-finance/alter/customerDepositPay/account?orderNo=${orderNo}`,params,{headers: {'application': 'application/json'}})
    },
    //修改中间退费付款账号【只修改某一个合同相关的】
    postMiddleFadeFeeAccount(orderNo,params){
      return instance.post(`/pricing-finance/alter/middleFadeFee/account?orderNo=${orderNo}`,params,{headers: {'application': 'application/json'}})
    },
    //待付款状态下，根据渠道钱包支付ID回退到提现任务待审核状态
    postChannelWalletPayRollback(params){
      return instance.post(`/pricing-finance/alter/channelWalletPay/rollback?${qs.stringify(params)}`,params,{headers: {'application': 'application/json'}})
    },
    //修改门店归属
    postAlterShopOwner(params){
      return instance.post(`/pricing-check/alter/alterShopOwner?${qs.stringify(params)}`,params,{headers: {'application': 'application/json'}})
    },
   // 车开新提供接口8：办证公司申请结算
    postComApplyClear(params){
      return instance.post(`/pricing-trade/cert/ckx/comApplyClear`,params,{headers: {'application': 'application/json'}})
    },

    /**业务修改数据结束**/

    
    
  };

  export default $api;
