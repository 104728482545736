<template>
  <div class="settlemQueryBox">
      <el-row :gutter="20">
          <el-col :span="5" class="flex flex_align_center flex_between w3">
              <span>车牌号</span>
              <el-input v-model="forms.carNo" size="medium"></el-input>
          </el-col>
          <el-col :span="5" class="flex flex_align_center flex_between w3">
              <span>商户名</span>
              <el-input v-model="forms.merchantName" size="medium"></el-input>
          </el-col>
          <el-col :span="5" class="flex flex_align_center flex_between w6">
              <span>商户结算状态</span>
              <el-select v-model="forms.merchantState" placeholder="请选择商户结算状态" size="medium" clearable>
                  <el-option label="未结算" :value="121"></el-option>
                  <el-option label="已结算" :value="125"></el-option>
              </el-select>
          </el-col>
          <el-button type="primary" @click="init(1)" size="medium" plain>查询</el-button>
      </el-row>
      <el-table :data="querylist" border stripe class="querylistTable table-mainbg" >
        <el-table-column prop="carNo" label="车牌号码" align="center" ></el-table-column>
        <el-table-column prop="makerName" label="品牌车型" align="center" >
          <template slot-scope="scope">{{ scope.row.car.makerName }}&nbsp;{{scope.row.car.seriesName}}</template>
        </el-table-column>
        <el-table-column prop="merchantName" label="商户名称" align="center" >
          <!-- <template slot-scope="scope">{{scope.row.car.carMerchant?scope.row.car.carMerchant.merchantType==1?scope.row.car.carMerchant.authCompanyName:scope.row.car.carMerchant.merchantName:scope.row.merchantName}}</template> -->
        </el-table-column>
        <el-table-column prop="merchantDeadline" label="商户办证截止日期" align="center" ></el-table-column>
        <el-table-column prop="certOverdueCheckedTime" label="办证结算时间" align="center" ></el-table-column>
        <el-table-column prop="merchantClearStatusName" label="商户结算状态" align="center" ></el-table-column>
        <!-- <el-table-column prop="taskComClearStatusName" label="办证公司结算状态" align="center" ></el-table-column> -->
        <el-table-column label="操作" width="100" align="center">
          <template slot-scope="scope">
            <el-button type="primary" @click="onsee(scope.row)" size="mini">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      :current-page="currentPage"
      :total="total">
      </el-pagination>

      <el-dialog
      :visible.sync="dialogVisible" width="1000px">
      <el-table :data="detailist" border  class="querylistTable " 
      height="350">
        <el-table-column prop="itemName" label="费用项目" align="center" ></el-table-column>
        <el-table-column prop="customerFeeAmount" label="客户" align="center" ></el-table-column>
        <el-table-column prop="merchantFeeAmount" label="商户" align="center" ></el-table-column>
        <el-table-column prop="certFeeAmount" label="办证公司" align="center" ></el-table-column>
        <el-table-column prop="ckxFeeAmount" label="车开新" align="center" ></el-table-column>
        <el-table-column prop="ruleFeeAmount" label="违章处理公司" align="center" ></el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
      </el-dialog>
  </div>
</template>
<script>
export default {
    data(){
      return{
        forms:{
            carNumber:'',
            merchantState:'',
            companyState:'',
        },
        querylist:[],
        detailist:[],
        dialogVisible:false,
        total:0,
        currentPage:1,
        pageSize:20,
        itemRow:{},
      }
    },
    created(){
      this.init()
    },
    methods:{
      init(page) {
        this.currentPage=page?page:this.currentPage;
        let query = {
          curPage: this.currentPage, //当前页数
          pageSize: this.pageSize,
          carNo:this.forms.carNo,
          merchantName:this.forms.merchantName,
          merchantClearStatuses:this.forms.merchantState?[this.forms.merchantState]:[121,125],//办证公司-结算管理: 121 待结算,122 审核中,123 审核驳回,124 审核通过,125 结算完成
        };
        this.$api.getCertificateTaskPage(this.$$.delete_null_properties(query)).then((res) => {
          if (res) {
            if (res.code == 0) {
              this.querylist = res.data.records;
              this.total = res.data.total;
            } else {
              this.querylist = [];
              this.total = 0;
            }
          }
        });
      },
      onsee:function(row){
        this.dialogVisible=!this.dialogVisible;
        this.$api.getThirdClearList(row.carId).then(res=>{
          if(res.code==0){
            this.detailist=res.data.thirdClearFees;
          }
        })
      },
      handleSizeChange(data) {
        this.pageSize = data;
        this.currentPage=1;
        this.init()
      },
      handleCurrentChange(data) {
        this.currentPage = data;
        this.init()
      }
    }
}
</script>
<style lang="scss" scoped>
.settlemQueryBox{
  padding: 40px;
  
  .querylistTable{
      margin-top: 30px;
  }
}
</style>
