<template>
  <div class="certificateMaterBox">
    <el-tabs v-model="tabClassify" type="card" @tab-click="init(1)">
      <el-tab-pane label="退客户押金列表" name="first">
        <el-row :gutter="24" class="mt30">
          <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w3 mb15">
            <span>车牌号</span>
            <el-input v-model="forms.carNo" size="medium"></el-input>
          </el-col>
          <el-col :xl="4" :lg="6"  class="flex flex_align_center flex_between w5 mb15">
            <span>退款押金状态</span>
            <el-select v-model="forms.financePayStatuses" clearable multiple>
              <!-- <el-option label="全部" value="" ></el-option> -->
              <el-option
                v-for="item in paymentStatuslist"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :xl="8" :lg="8" class="flex flex_align_center flex_between w5 mb15">
            <span>客户结算时间</span>
            <el-date-picker
              v-model="forms.date"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
          </el-col>
          <el-col :xl="2" :lg="2" class="flex flex_align_center flex_between w4 mb15">
            <el-button type="primary" @click="init(1)" size="medium" plain
              >查询</el-button
            >
          </el-col>
          <el-col :xl="2" :lg="2"  class="flex flex_align_center flex_between w4 mb15">
            <el-button type="primary" @click="onBatchPayment" size="medium"
              >批量付款</el-button
            >
          </el-col>
        </el-row>
        <el-table
          :data="querylist"
          border
          stripe :row-key="getRowKeys" 
          class="table-mainbg mt5"
          ref="table"
          @selection-change="selectChange"
        >
          <el-table-column
            type="selection"
            label="选择" :reserve-selection="true"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="customerContractNo"
            label="合同编号"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="carNo"
            label="车牌号"
            align="center"
          ></el-table-column>
          <el-table-column prop="carBrandModelName" label="车型" align="center"></el-table-column>
          <el-table-column
            prop="mcTradePrice"
            label="成交价（元）"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="tradeTypeName"
            label="成交类型"
            align="center"
          >
            <template slot-scope="scope">{{scope.row.tradeType==21?'预约成交':scope.row.tradeType==22?'垫资成交':scope.row.tradeTypeName}}</template>
          </el-table-column>
          <el-table-column
            prop="appointTradeTime"
            label="预约成交时间"
            align="center"
          ></el-table-column>
          <!-- <el-table-column
            prop="customerName"
            label="店长审核时间"
            align="center"
          ></el-table-column> -->
          <el-table-column
            prop="totalPayAmount"
            label="应退金额"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="financePayStatus"
            label="退押金状态"
            align="center" >
            <template slot-scope="scope">{{scope.row.financePayStatus==32?'付款中':scope.row.financePayStatus==33?'付款成功':scope.row.financePayStatus==34?'付款失败':'待付款'}}</template>
          </el-table-column>
          <el-table-column
            prop="certOverdueCheckedTime"
            label="客户结算时间"
            align="center"
          ></el-table-column>
          <!-- <el-table-column
            prop="remark"
            label="备注"
            align="center"
          ></el-table-column> -->
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button type="primary" @click="onRowClick(scope.row, 0)" size="mini">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="mt30"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, prev, pager, next,sizes, jumper"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="pageSize"
          :current-page="currentPage"
          :total="total"
        >
        </el-pagination>
      </el-tab-pane>
      <el-tab-pane label="批量退客户押金列表" name="second">
        <el-row :gutter="20" class="mt30">
          <el-col :span="6" class="flex flex_align_center flex_between w5">
            <span>批量支付日期</span>
            <el-date-picker
              v-model="forms1.date"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-col>
          <el-col :span="5" class="flex flex_align_center flex_between w3">
            <span>车牌号</span>
            <el-input v-model="forms1.carNo" size="medium"></el-input>
          </el-col>
          <el-col :span="5" class="flex flex_align_center flex_between w5">
            <span>批量支付状态</span>
            <el-select v-model="forms1.batchPayStatus" clearable>
              <el-option value="">请选择状态</el-option>
              <el-option
                v-for="item in BatchPayStatuslist"
                :key="item.value"
                :value="item.value"
                :label="item.label"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :span="3" class="flex flex_align_center flex_between w4">
            <el-button type="primary" @click="init(1)" size="medium" plain
              >查询</el-button>
          </el-col>
        </el-row>
        <el-table
          :data="querylist1"
          border
          stripe
          class="table-mainbg mt20"
        >
          <el-table-column
            prop="batchPayOrderNo"
            label="批次编号"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="batchPayAmount"
            label="批量支付金额（元）"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="batchPayTime"
            label="批量支付日期"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="batchPayStatus"
            label="批量支付状态"
            align="center"
          >
          <template slot-scope="scope">{{scope.row.batchPayStatus==32?'付款中':scope.row.batchPayStatus==33?'付款成功':scope.row.batchPayStatus==34?'付款失败':'待付款'}}</template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button type="primary" @click="onRowClick(scope.row, 1)" size="mini">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="mt30"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, prev, pager, next,sizes, jumper"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="pageSize1"
          :current-page="currentPage1"
          :total="total1"
        >
        </el-pagination>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tabClassify: "first",
      querylist: [], //
      querylist1: [], //
      forms: {
        carNo: "",
        date: "",
        financePayStatuses:[31,32],
      },
      forms1:{},
      selectValue: "",
      total: 0,
      total1: 0,
      currentPage: 1,
      currentPage1: 1,
      pageSize: 10,
      pageSize1: 10,
      paymentStatuslist: [],
      BatchPayStatuslist: [],
      selectlist: [], //选中的数据
      stateType: "", //判断是否从详情来展示对应的tab
    };
  },
  created() {
    if(this.$route.params.state) this.forms.financePayStatuses=[Number(this.$route.params.state)];
    
    this.paymentStatuslist = this.$store.state.setting.paymentStatuslist;
    this.BatchPayStatuslist = this.$store.state.setting.BatchPayStatuslist;
    this.init();
  },
  methods: {
    getRowKeys(row) {
      return row.id
    },
    init(page) {
      if (this.tabClassify == "first") {
        this.postCustomerDeposit(page)//条件查询
      } else {
        this.postCustomerDepositBatchQuery(page)//批量条件查询
      }
    },
    handleSizeChange(data) {
      switch (this.tabClassify) {
        case "first":
          this.pageSize = data;
          this.currentPage=1;
          break;
        case "second":
          this.pageSize1 = data;
          this.currentPage1=1;
          break;
      }
      this.init();
    },
    handleCurrentChange(data) {
      switch (this.tabClassify) {
        case "first":
          this.currentPage = data;
          break;
        case "second":
          this.currentPage1 = data;
          break;
      }
      this.init();
    },
    onRowClick(row, state) {
      let query={
        state: state
      };
      if(state==0){
        query.depositPayId = row.id
      }else{
        query.batchPayOrderNo=row.batchPayOrderNo;
      }
      this.$router.push({
        path: "/finance/customerDepositDetails",
        query: query,
      });
    },
    selectChange(selection){
     this.selectlist = selection;
    },
    onBatchPayment() {
      let arr=[];
      let payType=false;//当前是否有单存在付款完成
      for(let i=0;i<this.selectlist.length;i++){
        if(this.selectlist[i].financePayStatus!=31){
          payType=true;
        }
        arr.push(this.selectlist[i].carNo)
      }
      if(payType){
        this.$message.warning('当前订单中存在不需要付款的数据，请确认再付款！');
        return
      }
      if (this.selectlist.length == 0) {
        this.$message("请选择需要批量付款的合同！");
        return;
      }
      this.$store.dispatch('setting/setCustomerDepositList',this.selectlist); 
      let aurl = "/finance/customerDepositDetails";
      this.$router.push({
        path: aurl,
        query: {
          state: 1,
          carNos:arr
        },
      });
    },
    postCustomerDeposit(page){//客户押金退款 条件查询
      this.currentPage=page?page:this.currentPage;
      let query={
        curPage:this.currentPage,
        pageSize:this.pageSize,
        carNo:this.forms.carNo,
        financePayStatuses:this.forms.financePayStatuses?this.forms.financePayStatuses:'',//财务付款状态，31待付款，32付款中，33付款成功，34付款失败
        certClearBeginTime:this.forms.date?this.forms.date[0]:'',
        certClearEndTime:this.forms.date?this.forms.date[1]:'',
      }
      this.$api.postCustomerDeposit(this.$$.delete_null_properties(query)).then(res=>{
        if (res.code == 0) {
          this.querylist = res.data.records;
          this.total = res.data.total;
        } else {
          this.querylist = [];
          this.total = 0;
        }
      })
    },
    postCustomerDepositBatchQuery(page){//客户押金退款 批量条件查询
      this.currentPage1=page?page:this.currentPage1;
      let query={
        curPage:this.currentPage1,
        pageSize:this.pageSize1,
        carNo:this.forms1.carNo,
        batchPayStatus:this.forms1.batchPayStatus?[this.forms1.batchPayStatus]:'',//批量支付状态，32付款中，33付款成功，34付款失败
        batchPayBeginTime:this.forms1.date?`${this.forms1.date[0]} 00:00:00`:'',//批量支付开始日期
        batchPayEndTime:this.forms1.date?`${this.forms1.date[1]} 23:59:59`:'',
      }
      this.$api.postCustomerDepositBatchQuery(this.$$.delete_null_properties(query)).then(res=>{
        if (res.code == 0) {
          this.querylist1 = res.data.records;
          this.total1 = res.data.total;
        } else {
          this.querylist1 = [];
          this.total1 = 0;
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.certificateMaterBox {
  padding: 40px;
  .searchRowBox {
    margin: 30px 0;
  }
}
</style>